import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import VideoCover from 'react-video-cover';
import { RichText } from 'prismic-reactjs';
import {
  Container,
} from 'react-bootstrap';

import { APIENDPOINT } from './config/constant';
import { trimPrismicUrl } from './helpers/prismic';
import Slider from './Common/Slider';
import GlobalFooter from './Common/GlobalFooter';
// import Pinned from './Common/Pinned';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homepage: {
        body: [],
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  // eslint-disable-next-line class-methods-use-this
  getPrismic() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(
        Prismic.Predicates.at('document.type', 'tfi_home'),
        {
          fetchLinks: ['blogs.title', 'blogs.image'],
        },
      ).then((response) => {
        if (response) {
          // console.log(response);
          this.setState({
            homepage: response.results[0].data,
          });
        }
      });
    });
  }

  render() {
    const { homepage } = this.state;
    // console.log(homepage);
    return (
      <div className="home">
        {homepage.body.map((slice, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <HomeSlice slice={slice} />
          </div>
        ))}
        {/*
        <Container>
          <Pinned />
        </Container>
        */}
        <GlobalFooter />
      </div>
    );
  }
}

Home.propTypes = {
};

export default Home;

function HomeSlice(props) {
  const { slice } = props;
  // console.log(slice);
  let slides = [];
  switch (slice.slice_type) {
    case 'simple_image_banner':
      return (
        <Container>
          <div>
            <a
              style={{
                backgroundColor: 'rgb(111, 175, 219)',
                padding: '52px',
                borderRadius: '10px',
                display: 'block',
              }}
              href={slice.primary.url}
            >
              <img
                src={slice.primary.banner.url}
                alt={slice.primary.alt_text}
              />
            </a>
          </div>
        </Container>
      );
    case 'cover_video':
      return (
        <div
          className="hero"
        >
          <Container>
            <h1 className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <img src="/img/tfi_w.png" alt="Tribeca Film Institute" />
            </h1>
            <div className="text">
              <HeroText slice={slice} />
            </div>
          </Container>
          <div
            className="covervideo-wrapper"
          >
            <VideoCover
              videoOptions={{
                src: slice.primary.video_url,
                autoPlay: true,
                muted: true,
                loop: true,
              }}
            />
          </div>
        </div>
      );
    case 'blog_posts':
      slides = slice.items.map((item) => ({
        uid: item.blog_posts.uid,
        url: `/blog/detail/${item.blog_posts.uid}`,
        title: RichText.asText(item.blog_posts.data.title),
        image: `${trimPrismicUrl(item.blog_posts.data.image.url)}&fit=crop&w=960&h=540`,
        button: 'Learn More',
      }));
      // console.log(slides);
      return (
        <Container>
          <div>
            <Slider slides={slides} actionButton={{ url: '/blog', label: 'View Blog' }} title="What&rsquo;s Happening at TFI" />
          </div>
        </Container>
      );
    default:
      return (<h1>{slice.slice_type}</h1>);
  }
}
HomeSlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string.isRequired,
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
};
HomeSlice.defaultProps = {
  slice: {
    items: [],
  },
};

function HeroText(props) {
  const { slice } = props;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter2) => counter2 + 1);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (<span>{RichText.render(slice.items[counter % slice.items.length].tag_line)}</span>);
}
HeroText.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
};
HeroText.defaultProps = {
  slice: {
    items: [],
  },
};
