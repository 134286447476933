/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container,
  Row, Col,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import fetch from 'node-fetch';
import camelcase from 'camelcase';

import { APIENDPOINT } from '../config/constant';
import DisplayDeadline from './common/DisplayDeadline';
import GlobalFooter from '../Common/GlobalFooter';
import { trimPrismicUrl } from '../helpers/prismic';
import StarModalProject from './Star/ModalProject';
import StarModalArtist from './Star/ModalArtist';
import StarMap from './Star/Map';

const ProgramStar = () => {
  const [entryData, setEntryData] = useState({
    background_color: '',
    key_points: [],
    sponsors: [],
    image: {},
    title: [],
    hint: [],
    body: [],
  });
  const { projectId } = useParams();
  const [currentKP, setCurrentKP] = useState('');
  const [starId, setStarId] = useState();
  const [projects, setProjects] = useState([]);
  const [registryEntries, setRegistryEntries] = useState([]);
  const [projectRelatedEntries, setProjectRelatedEtnries] = useState({});
  const [imageCache, setImageCache] = useState({});

  const handleKPClick = (kp) => {
    if (currentKP === kp) {
      setCurrentKP('');
    } else {
      setCurrentKP(kp);
    }
  };

  const uid = 'star';

  useEffect(() => {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.programs.uid', uid),
    ).then((res) => {
      let newCurrentKP = '';
      if (res.results[0].data.key_points[0] !== undefined) {
        newCurrentKP = res.results[0].data.key_points[0].heading;
      }
      setStarId(res.results[0].id);
      setEntryData(res.results[0].data);
      setCurrentKP(newCurrentKP);
    });
  }, []);
  useEffect(() => {
    const client = Prismic.client(APIENDPOINT);
    // console.log(starId);
    if (starId !== undefined) {
      client.query(
        Prismic.Predicates.at('my.projects.programs.program', starId),
        { fetchLinks: ['locations.name', 'locations.bid', 'people.name'] },
      ).then((res) => {
        setProjects(res.results);
        const relatedIds = [];
        res.results.forEach((project) => {
          project.data.showing.forEach((showing) => {
            relatedIds.push(showing.location.id);
          });
          project.data.credits.forEach((credit) => {
            relatedIds.push(credit.people.id);
          });
        });
        // get related docs
        client.query(
          Prismic.Predicates.in('document.id', relatedIds),
        ).then((res2) => {
          // console.log(res2);
          const rowById = {};
          res2.results.forEach((row) => {
            rowById[row.id] = row;
          });
          setProjectRelatedEtnries(rowById);
        });
      });
    }
  }, [starId]);

  // load registry
  useEffect(() => {
    fetch('https://vgmher7qt4.execute-api.us-east-1.amazonaws.com/dev/star-registry')
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        setRegistryEntries(json.artistRegistry);
        setImageCache(json.imageCache);
      });
  }, []);

  const style = {
    backgroundColor: '#6FAFDB',
  };
  if (
    entryData.background_color !== ''
  ) style.backgroundColor = entryData.background_color;
  const kpToShow = [];
  entryData.key_points.forEach((kp) => {
    if (kp.heading !== '' && RichText.asText(kp.info) !== '') kpToShow.push(kp);
  });

  const sponsorToShow = [];
  entryData.sponsors.forEach((sponsor) => {
    if (sponsor.sponsor_logo.url !== undefined) sponsorToShow.push(sponsor);
  });

  // load badge
  const badge = {};
  const today = moment().unix();
  projects.forEach((project) => {
    let projectBadge = 'Coming Soon';
    project.data.showing.forEach((show) => {
      if (moment(show.end).unix() < today) {
        projectBadge = 'Closed';
      } else if (moment(show.start).unix() < today && moment(show.end).unix() > today) {
        projectBadge = 'Open Now';
      }
    });
    badge[project.id] = projectBadge;
  });

  const [artistIndex, setArtistIndex] = useState(null);
  const [modalProjectId, setModalProjectId] = useState(null);

  useEffect(() => {
    if (projectId) setModalProjectId(projectId);
  }, [projects, projectId, projectRelatedEntries]);

  // load map markers
  const markerEntries = [];
  projects.forEach((pro) => {
    pro.data.showing.forEach((showing) => {
      if (projectRelatedEntries[showing.location.id]) {
        markerEntries.push({
          location: projectRelatedEntries[showing.location.id],
          project: pro,
        });
      }
    });
  });
  registryEntries.sort((a, b) => {
    if (a.nameOfArtistOrCollective.answer > b.nameOfArtistOrCollective.answer) return 1;
    if (a.nameOfArtistOrCollective.answer < b.nameOfArtistOrCollective.answer) return -1;
    return 0;
  });
  // console.log(projects);
  // console.log(registryEntries);
  return (
    <div className={`program-detail ${uid}`}>
      <StarModalArtist
        imageCache={imageCache}
        artistIndex={artistIndex}
        artists={registryEntries}
        setArtistIndex={setArtistIndex}
      />
      <StarModalProject
        projectId={modalProjectId}
        setProjectId={setModalProjectId}
        projects={projects}
        projectRelatedEntries={projectRelatedEntries}
        badge={badge}
      />
      <div className="hero" style={style}>
        <Container>
          <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
            <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
          </div>
          <div className="table-wrapper">
            <div className="cell-wrapper">
              <Row>
                <Col md={{ span: 8, offset: 4 }}>
                  <div className="program-logo">
                    {entryData.image.url !== undefined
                      ? <img src={`${entryData.image.url}&w=500`} alt={RichText.asText(entryData.title)} />
                      : null }
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={4}>
            {entryData.close !== null && moment(entryData.close).unix() > moment().unix()
              ? (
                <div className="deadlines">
                  <h3>Application Info</h3>
                  <DisplayDeadline program={{ data: entryData }} directLink />
                </div>
              ) : null}
            {entryData.event_date !== null && moment(entryData.event_date).unix() > moment().unix()
              ? (
                <div className="deadlines">
                  <h3>{`Event Date: ${moment(entryData.event_date).format('YYYY-MM-DD')}`}</h3>
                  {RichText.render(entryData.event_teaser)}
                </div>
              ) : null}

            {entryData.hint.length > 0
              ? (
                <div className="sidebar-note">
                  {RichText.render(entryData.hint)}
                </div>
              ) : null }
          </Col>
          <Col>
            {entryData.video !== undefined && entryData.video.html !== undefined
              ? <div dangerouslySetInnerHTML={{ __html: entryData.video.html }} className="program-video" />
              : null}
            {/**
             * <h1>{RichText.asText(entryData.title)}</h1>
             */}
            {kpToShow.map((kp) => (
              <div className="keypoints" key={kp.heading}>
                <button type="button" onClick={() => handleKPClick(kp.heading)} className="as-text heading">
                  <span className={`icon-${currentKP === kp.heading ? 'minus' : 'plus'}`} />
                  {' '}
                  {kp.heading}
                </button>
                <div className={`info ${currentKP === kp.heading ? 'active' : ''}`}>{RichText.render(kp.info)}</div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="blue-bg-wrapper">
          <h2>STAR Sites</h2>
          <Row>
            {projects.map((project) => (
              <Col key={project.id} md={4} className="star-site">
                <button
                  type="button"
                  className="as-text"
                  onClick={() => setModalProjectId(project.uid)}
                >
                  <img
                    src={`${trimPrismicUrl(project.data.image.url)}&fit=crop&w=600&h=240`}
                    alt="project"
                    className="site-image"
                  />
                  {badge[project.id] ? <div className={`showing-status ${camelcase(badge[project.id])}`}>{badge[project.id]}</div> : null}
                  <div>
                    <h3>{RichText.asText(project.data.title)}</h3>
                  </div>
                  {project.data.credits.map((credit) => (
                    <div key={credit.people.id}>
                      <span className="fas fa-palette" />
                      {' '}
                      {`${credit.role !== '' ? credit.role : 'Artist'}: ${RichText.asText(credit.people.data ? credit.people.data.name : [])}`}
                    </div>
                  ))}
                  {project.data.showing.map((show) => (
                    <div key={show.start}>
                      <span className="fas fa-map-marker-alt" />
                      {' '}
                      {RichText.asText(show.location.data.name)}
                      {show.location.data.bid && show.location.data.bid !== '' ? (
                        <span>
                          <br />
                          <span className="fas fa-store" />
                          {' '}
                          {show.location.data.bid}
                        </span>
                      ) : null}
                      {/**
                      <br />
                      <span className="fas fa-calendar" />
                      {' '}
                      {moment(show.start).format('M/D')}
                      */}
                    </div>
                  ))}
                </button>
              </Col>
            ))}
          </Row>
        </div>
        <div>
          <StarMap
            markerEntries={markerEntries}
            setModalProjectId={setModalProjectId}
          />
        </div>
        <div className="black-bg-wrapper artist-registry">
          <h2>STAR Artist Registry</h2>
          <p>
            <b><a href="/star/contact">Contact Us</a></b>
            {' '}
            if you would like to partner with the following
            {' '}
            artists to display their work at your property.
          </p>
          <Row>
            {registryEntries.map((artist, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <Col key={i} lg={3} xs="6" className="artist-col">
                <button
                  type="button"
                  className="as-text"
                  onClick={() => setArtistIndex(i)}
                >
                  <div>
                    <img
                      src={imageCache[artist.headshotOrArtCollectiveLogoImage.answer[0]]
                        ? imageCache[artist.headshotOrArtCollectiveLogoImage.answer[0]].replace(/\/upload\//, '/square/upload/')
                        : `${artist.headshotOrArtCollectiveLogoImage.answer[0]}?width=400&height=400`}
                      alt="headshot"
                      className="headshot"
                    />
                  </div>
                  <h3>{artist.nameOfArtistOrCollective.answer}</h3>
                  <div>
                    <span className="fas fa-map-marker-alt" />
                    {' '}
                    {artist[`neighborhoodOfResidencyOrStudio${camelcase(artist.boroughOfResidencyOrStudio.answer, { pascalCase: true })}`].answer}
                    {` (${artist.boroughOfResidencyOrStudio.answer})`}
                  </div>
                  <div>
                    <span className="fas fa-palette" />
                    {' '}
                    {artist.primaryArtisticDiscipline.answer ? artist.primaryArtisticDiscipline.answer.join(', ') : null}
                  </div>
                </button>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-md-center contact-star">
            <Col lg={6}>
              <h2>Contact Us</h2>
              <form action="https://usebasin.com/f/c2377b392f29" method="POST">
                <label htmlFor="email-address">Your Email</label>
                <input type="email" id="email" name="email" required />
                <label htmlFor="name">Your Name</label>
                <input type="text" id="name" name="name" required />
                <label htmlFor="subject">Subject</label>
                <select id="subject" name="subject" required>
                  <option value="Own a property and would like to participate">Own a property and would like to participate</option>
                  <option value="Interested participating as an artist">Interested participating as an artist</option>
                  <option value="Other">Other</option>
                </select>
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" required />
                <input type="hidden" id="test" name="test" required />
                <button type="submit">Submit</button>
              </form>
            </Col>
          </Row>

        </div>
      </Container>
      <GlobalFooter />
    </div>
  );
};

ProgramStar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
      programEventUid: PropTypes.string,
    }),
  }),
};

ProgramStar.defaultProps = {
  match: {
    params: {},
  },
};
export default ProgramStar;
