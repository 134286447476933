import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';

import { APIENDPOINT } from '../../config/constant';

import Slider from '../../Common/Slider';
import { trimPrismicUrl } from '../../helpers/prismic';

class SliceByProgramEvents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const { programId } = this.props;
    const client = Prismic.client(APIENDPOINT);
    client.query(
      [
        Prismic.Predicates.at('document.type', 'program_events'),
        Prismic.Predicates.at('my.program_events.program', programId),
      ],
      {
        pageSize: 6,
        orderings: '[my.program_events.event_date desc]',
      },
    ).then((res) => {
      // console.log(res);
      this.setState({
        entries: res.results,
      });
    });

    // console.log({ slice, programId });
  }


  render() {
    const { slice, programId } = this.props;
    const { entries } = this.state;
    // console.log({ slice, programId });
    const title = RichText.asText(slice.primary.slice_title);
    const slides = [];
    entries.forEach((entry) => {
      let url = `/program/event/${entry.uid}`;
      if (moment(entry.data.event_date).unix() < moment().unix()) {
        url = `/program/event/summary/${entry.uid}`;
      }
      slides.push({
        uid: entry.uid,
        title: RichText.asText(entry.data.title),
        image: `${trimPrismicUrl(entry.data.listing_logo.url)}&fit=crop&w=640&h=360`,
        url,
      });
    });

    const actionButton = {
      url: `/program/event/all/${programId}`,
      label: 'See All Pitches',
    };
    const slidesToShow = 3;
    return (
      <Slider
        slides={slides}
        actionButton={actionButton}
        title={title}
        slidesToShow={slidesToShow}
      />
    );
  }
}

SliceByProgramEvents.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      slice_title: PropTypes.arrayOf(PropTypes.object),
      slice_title_custom: PropTypes.arrayOf(PropTypes.object),
      year: PropTypes.string,
      type: PropTypes.string,
      action_url: PropTypes.string,
      action_label: PropTypes.string,
      program_note: PropTypes.string,
    }),
  }),
  programId: PropTypes.string.isRequired,
};

SliceByProgramEvents.defaultProps = {
  slice: {
    primary: {
      slice_title_custom: [],
      slice_title: [],
      year: '',
      type: '',
      action_url: '',
      action_label: '',
      program_note: null,
    },
  },
};
export default SliceByProgramEvents;
