import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../config/constant';
import GlobalFooter from '../Common/GlobalFooter';
import RenderBodySlice from '../Common/BodySlice/RenderBodySlice';

class ProgramEventSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          body1: [],
          sponsors: [],
          sponsor_title: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.getPrismic(match.params.uid);
  }

  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.program_events.uid', uid),
    ).then((res) => {
      console.log(res.results);
      this.setState({
        entry: res.results[0],
      });
    });
  }

  render() {
    const { entry } = this.state;
    return (
      <div className="program event summary">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>{RichText.asText(entry.data.title)}</h1>
            <Row>
              <Col lg={8}>
                <div className="summary-body">
                  {entry.data.body1.map((slice, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <RenderBodySlice slice={slice} key={i} />
                  ))}
                </div>
              </Col>
              <Col>
                {entry.data.sponsors.length > 0 && entry.data.sponsors[0].sponsor_label !== ''
                  ? (
                    <div className="sponsors">
                      <h3>{RichText.asText(entry.data.sponsor_title)}</h3>
                      {entry.data.sponsors.map((sponsorRow, i) => (
                        <div key={i}>
                          <a href={sponsorRow.sponsor_url.url}>
                            <img src={sponsorRow.sponsor_logo.url} alt="sponsor logo" />
                            <div className="sponsor-label">
                              {sponsorRow.sponsor_label}
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : null }
              </Col>
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

ProgramEventSummary.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};
ProgramEventSummary.defaultProps = {
  match: {
    params: {},
  },
};

export default ProgramEventSummary;
