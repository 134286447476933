import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import GlobalFooter from './Common/GlobalFooter';

const Page404 = () => (
  <div className="page-404">
    <div className="bg-wrapper">
      <Container>
        <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
          <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
        </div>
        <h1>Page Not Found</h1>
      </Container>
    </div>
    <GlobalFooter />
  </div>
);

Page404.propTypes = {
};
Page404.defaultProps = {
};

export default Page404;
