import React from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from './config/constant';
import { trimPrismicUrl } from './helpers/prismic';
import GlobalFooter from './Common/GlobalFooter';

class Featured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('document.type', 'featured_filmmakers'),
      {
        fetchLinks: ['people.name', 'people.headshot'],
      },
    ).then((res) => {
      this.setState({
        entries: res.results[0].data.filmmakers,
      });
    });
  }

  render() {
    const { entries } = this.state;
    // console.log(entries);
    return (
      <div className="featured">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>Featured Filmmakers</h1>
            <Row>
              {entries.map((entry) => (
                <Col xl={3} lg={4} md={6} key={entry.people.id}>
                  <div className="filmmaker-col">
                    <Link to={`/filmmakers/detail/${entry.people.uid}`}>
                      <img
                        src={`${trimPrismicUrl(entry.people.data.headshot.url)}&fit=crop&w=250&h=250`}
                        alt={RichText.asText(entry.people.data.name)}
                        className="headshot"
                      />
                    </Link>
                    <h2>
                      <Link to={`/filmmakers/detail/${entry.people.uid}`}>
                        {RichText.asText(entry.people.data.name)}
                      </Link>
                    </h2>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

Featured.propTypes = {
};
Featured.defaultProps = {
};

export default Featured;
