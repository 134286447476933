/* eslint-disable no-undef */
import React from 'react';
import {
  Container,
} from 'react-bootstrap';

import GlobalFooter from './Common/GlobalFooter';
import TopBanner from './Common/TopBanner';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener('message', this.handleResize, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', this.handleResize);
    }
  }


  // eslint-disable-next-line class-methods-use-this
  handleResize(event) {
    if (event.origin !== 'https://tfiny.force.com') return;
    // console.log(event.data);
    const donationIframe = document.getElementById('donationFormIframe');
    if (donationIframe) {
      donationIframe.style.height = `${event.data}px`;
    }
  }

  render() {
    return (
      <div className="support">
        <TopBanner uid="you-make-the-difference" />
        <Container>
          <iframe
            title="test"
            id="donationFormIframe"
            scrolling="auto"
            src="https://tfiny.secure.force.com/donate/?id=7011J000000xB4DQAU"
            style={{
              border: 'none',
              width: '100%',
              padding: 'none',
              margin: 'none',
              height: '2300px',
            }}
          />
        </Container>
        <GlobalFooter />
      </div>
    );
  }
}

Home.propTypes = {
};

export default Home;
