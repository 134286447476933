/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import urlencode from 'urlencode';
import { Row, Col } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import {
  APIENDPOINT, SITE_URL, SITE_NAME, SITE_IMAGE,
} from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import Pinned from '../Common/Pinned';
import SocialShareOut from '../Common/SocialShareOut';
import RenderBodySlice from '../Common/BodySlice/RenderBodySlice';

class BlogDetail extends React.PureComponent {
  static showCategories(cats) {
    const catsToShow = [];
    cats.forEach((cat) => {
      if (cat.category.data) {
        if (cat.category.data.category) {
          catsToShow.push(<Link to={`/blog/category/${cat.category.uid}`}>{RichText.asText(cat.category.data.category)}</Link>);
        } else {
          catsToShow.push(<Link to={`/blog/program/${cat.category.uid}`}>{RichText.asText(cat.category.data.title)}</Link>);
        }
      }
    });
    if (catsToShow.length === 0) return null;

    return (
      <div className="categories">
        <b>Filed in: </b>
        {catsToShow.map((item, i) => <span key={i}>{item}</span>).reduce((acc, x) => {
          if (acc === null) return [x];
          return [acc, ', ', x];
        }, null)}
      </div>
    );
  }

  static showTags(tags) {
    if (tags.length === 0) return null;
    return (
      <div className="tags">
        <b>Tags: </b>
        {tags.map((tag, i) => <Link key={i} to={`/blog/tag/${urlencode(urlencode(tag))}`}>{tag}</Link>).reduce((acc, x) => {
          if (acc === null) return [x];
          return [acc, ', ', x];
        }, null)}
      </div>
    );
  }


  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getBlogPost = this.getBlogPost.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let uidParam = '';
    if (match.params.uid !== undefined) uidParam = match.params.uid;
    this.getBlogPost(uidParam);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    let uidParam = '';
    if (match.params.uid !== undefined) uidParam = match.params.uid;
    if (prevProps.match.params.uid !== match.params.uid) {
      this.getBlogPost(uidParam);
    }
  }

  getBlogPost(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.blogs.uid', uid),
      { fetchLinks: ['blog_categories.category', 'programs.title'] },
    ).then((res) => {
      this.setState({
        entries: res.results,
      });
      // console.log(res);
    });
  }


  render() {
    const { entries } = this.state;
    return (
      <>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            {entries.map((entry, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={i}>
                <Helmet>
                  <title>{`${RichText.asText(entry.data.title)} - ${SITE_NAME}`}</title>
                  <meta name="description" content={RichText.asText(entry.data.teaser)} />

                  <meta property="og:title" content={RichText.asText(entry.data.title)} />
                  <meta property="og:description" content={RichText.asText(entry.data.teaser)} />

                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={entry.data.image && entry.data.image.url ? entry.data.image.url : SITE_IMAGE} />
                </Helmet>

                <h1>{RichText.asText(entry.data.title)}</h1>
                <div className="date">
                  {moment(entry.data.date).format(' YYYY-MM-DD')}
                </div>
                {entry.data.video && entry.data.video.html
                  // eslint-disable-next-line react/no-danger
                  ? <div dangerouslySetInnerHTML={{ __html: entry.data.video.html }} className="blog-video" />
                  : (
                    <>
                      {entry.data.image && entry.data.image.w800
                        ? <img src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=960&h=540`} alt={RichText.asText(entry.data.title)} className="blog-image" />
                        : null }
                    </>
                  )}
                <Row>
                  <Col>
                    <div className="blog-body">
                      {entry.data.body.map((slice, j) => (
                        <RenderBodySlice slice={slice} key={j} />
                      ))}
                    </div>
                  </Col>
                  <Col lg={3}>
                    {BlogDetail.showCategories(entry.data.categories)}
                    {BlogDetail.showTags(entry.tags)}
                    <SocialShareOut
                      url={`${SITE_URL}/blog/detail/${entry.uid}`}
                      title={RichText.asText(entry.data.title)}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Pinned />
          </Col>
        </Row>
      </>
    );
  }
}

BlogDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
    }),
  }),
};

BlogDetail.defaultProps = {
  match: {
    params: {},
  },
};
export default BlogDetail;
