import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import GlobalFooter from '../Common/GlobalFooter';

class TeamDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          name: [],
          headshot: {},
          title: [],
          bio: [],
        },
      },
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let uidParam = '';
    if (match.params.uid !== undefined) uidParam = match.params.uid;
    this.getEntries(uidParam);
  }

  getEntries(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.staff.uid', uid),
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      // console.log(res);
    });
  }

  render() {
    const { entry } = this.state;
    return (
      <div className="team detail">
        <div className="bg-wrapper">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={10}>
                <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
                  <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
                </div>
                <img
                  src={`${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=250&h=250`}
                  alt={RichText.asText(entry.data.name)}
                  className="headshot"
                />
                <h1>{RichText.asText(entry.data.name)}</h1>
                <div className="team-title">{RichText.asText(entry.data.title)}</div>
                <div className="bio">{RichText.render(entry.data.bio)}</div>
              </Col>
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

TeamDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
    }),
  }).isRequired,
};

export default TeamDetail;
