import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import { APIENDPOINT } from '../../config/constant';

import Slider from '../../Common/Slider';
import { trimPrismicUrl } from '../../helpers/prismic';

function SliceByBlogPosts({ slice }) {
  // console.log(slice);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const blogIds = slice.items.map((item) => (item.blog_post.id));
    // console.log(blogIds);
    Prismic.api(APIENDPOINT).then((api) => {
      api.query(
        Prismic.Predicates.in('document.id', blogIds),
      ).then((response) => {
        if (response) {
          setPosts(response.results);
          // console.log(response.results);
        }
      });
    });
  }, [slice]);

  const slides = posts.map((post) => ({
    uid: post.uid,
    url: `/blog/detail/${post.uid}`,
    title: RichText.asText(post.data.title),
    image: `${trimPrismicUrl(post.data.image.url)}&fit=crop&w=960&h=540`,
    button: 'Learn More',
  }));
  const actionButton = {};
  if (
    slice.primary.action_url !== ''
    && slice.primary.action_url !== null
    && slice.primary.action_label !== ''
    && slice.primary.action_label !== null
  ) {
    actionButton.url = slice.primary.action_url;
    actionButton.label = slice.primary.action_label;
  }
  // console.log(slides);
  return (
    <Slider
      slides={slides}
      actionButton={actionButton}
      title={RichText.asText(slice.primary.slice_title)}
    />
  );
}
SliceByBlogPosts.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
    primary: PropTypes.shape({
      action_url: PropTypes.string,
      action_label: PropTypes.string,
      slice_title: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};


export default SliceByBlogPosts;
