import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Analytics from 'react-router-ga';

import './css/main.css';
import Home from './Home';
import BlogRoute from './Blog/BlogRoute';
import FilmRoute from './Film/FilmRoute';
import FilmmakerRoute from './Filmmaker/FilmmakerRoute';
import GlobalNav from './Common/GlobalNav';
import Featured from './Featured';
import ProgramGroup from './Program/ProgramGroup';
import ProgramDetail from './Program/ProgramDetail';
import ProgramDeadline from './Program/ProgramDeadline';
import ProgramEventAll from './Program/ProgramEventAll';
import Support from './Support';
import Team from './Team/TeamIndex';
import TeamDetail from './Team/TeamDetail';
import EventIndex from './Events/EventIndex';
import EventDetail from './Events/EventDetail';
import CareerIndex from './Career/CareerIndex';

import {
  SITE_NAME,
  SITE_DESCRIPTION,
  SITE_IMAGE,
} from './config/constant';
import Search from './Search';
import PressIndex from './Press/PressIndex';
import PressDetail from './Press/PressDetail';
import ProgramEventSummary from './Program/ProgramEventSummary';
import About from './About';
import Timeline from './Timeline';
import Page404 from './Page404';
import ProgramStar from './Program/ProgramStar';
import ContactStar from './ContactStar';

function ScrollToTop(props) {
  useEffect(() => {
    const { history } = props;
    history.listen(() => {
      // eslint-disable-next-line no-undef
      window.scrollTo(0, 0);
    });
  });
  return (
    <></>
  );
}

ScrollToTop.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

function App() {
  // console.log(process.env);
  return (
    <div className="App">
      <Helmet>
        <title>{SITE_NAME}</title>
        <meta name="description" content={SITE_DESCRIPTION} />

        <meta property="og:title" content={SITE_NAME} />
        <meta property="og:description" content={SITE_DESCRIPTION} />

        <meta property="og:type" content="article" />
        <meta property="og:image" content={SITE_IMAGE} />
      </Helmet>
      <Router>
        <GlobalNav />
        <Route path="/" component={ScrollToTop} />
        <Analytics id="UA-1312040-6">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/star" component={ProgramStar} />
            <Route exact path="/star/contact" component={ContactStar} />
            <Route exact path="/star/:projectId" component={ProgramStar} />
            <Route exact path="/program/:uid" component={ProgramGroup} />
            <Route exact path="/program/detail/star" component={ProgramStar} />
            <Route exact path="/programs/detail/star" component={ProgramStar} />
            <Route exact path="/program/detail/:uid" component={ProgramDetail} />
            <Route exact path="/programs/detail/:uid" component={ProgramDetail} />
            <Route exact path="/program/event/:programEventUid" component={ProgramDetail} />
            <Route exact path="/program/event/all/:uid" component={ProgramEventAll} />
            <Route exact path="/program/event/summary/:uid" component={ProgramEventSummary} />
            <Route exact path="/program/deadline/:uid/:deadlineId" component={ProgramDeadline} />

            <Route exact path="/storytellers" component={Featured} />
            <Route exact path="/support" component={Support} />

            <Route path="/blog" component={BlogRoute} />
            <Route exact path="/films/:program" component={FilmRoute} />
            <Route exact path="/films/:program/:year" component={FilmRoute} />
            <Route path="/films" component={FilmRoute} />
            <Route path="/projects" component={FilmRoute} />
            <Route exact path="/filmmakers/:program" component={FilmmakerRoute} />
            <Route exact path="/filmmakers/:program/:year" component={FilmmakerRoute} />
            <Route path="/filmmakers" component={FilmmakerRoute} />

            <Route exact path="/team" component={Team} />
            <Route path="/team/:uid" component={TeamDetail} />

            <Route exact path="/events" component={EventIndex} />
            <Route exact path="/events/detail/:uid" component={EventDetail} />

            <Route exact path="/career" component={CareerIndex} />
            <Route exact path="/career/:id" component={CareerIndex} />

            <Route exact path="/search" component={Search} />
            <Route exact path="/search/:searchTerm" component={Search} />
            <Route exact path="/search/:searchTerm/:page" component={Search} />

            <Route exact path="/press" component={PressIndex} />
            <Route exact path="/press/:page" component={PressIndex} />
            <Route exact path="/press/detail/:uid" component={PressDetail} />

            <Route exact path="/about" component={About} />
            <Route exact path="/timeline" component={Timeline} />
            <Route component={Page404} />
          </Switch>
        </Analytics>
      </Router>
    </div>
  );
}

export default App;
