import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../config/constant';
import GlobalFooter from '../Common/GlobalFooter';

class CareerIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      career: {
        data: {
          title: [],
          disclaimer: [],
          teaser: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let id = '';
    if (match.params !== undefined && match.params.id) {
      ({ id } = match.params);
    }
    this.getPrismic(id);
  }

  getPrismic(id) {
    const client = Prismic.client(APIENDPOINT);
    if (id !== '') {
      client.query(
        Prismic.Predicates.at('document.id', id),
      ).then((res) => {
        // console.log(res.results);
        this.setState({
          entries: res.results,
        });
      });
    } else {
      client.query(
        Prismic.Predicates.at('document.type', 'jobs'),
      ).then((res) => {
        // console.log(res.results);
        this.setState({
          entries: res.results,
        });
      });
    }
    client.query(
      Prismic.Predicates.at('document.type', 'career_page'),
    ).then((res) => {
      // console.log(res.results);
      this.setState({
        career: res.results[0],
      });
    });
  }

  render() {
    const { entries, career } = this.state;
    // console.log(entries);
    return (
      <div>
        <div className="career">
          <Row className="justify-content-md-center">
            <Col lg={9}>
              <Container>
                <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
                  <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
                </div>
                <h1>{RichText.asText(career.data.title)}</h1>
                <div className="note">{career.data.note}</div>
                <div className="teaser">{RichText.asText(career.data.teaser)}</div>
                {entries.length > 0
                  ? <h2>{career.data.job_list_heading}</h2>
                  : <h2>{career.data.no_job_heading}</h2>
                }
                {entries.map(entry => (
                  <div key={entry.id}>
                    <h3>{RichText.asText(entry.data.position)}</h3>
                    {entries.length === 1
                      ? (
                        <div className="job-body">
                          {RichText.render(entry.data.description)}
                        </div>
                      ) : (
                        <div className="job-body">
                          {RichText.render(entry.data.short_description)}
                          <Link to={`/career/${entry.id}`} className="as-button">Learn More</Link>
                        </div>
                      )
                    }
                  </div>
                ))}
                <div className="disclaimer">
                  {RichText.render(career.data.disclaimer)}
                </div>
              </Container>
            </Col>
          </Row>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}


CareerIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};
CareerIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default CareerIndex;
