/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import {
  Row, Col,
} from 'react-bootstrap';
import camelcase from 'camelcase';

import fetch from 'node-fetch';
import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';


const FilmAllStar = ({ match }) => {
  const [artists, setArtists] = useState([]);
  const [entries, setEntries] = useState([]);
  // const [totalPages, setTotalPages] = useState(1);
  const getEntries = ({ page }) => {
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      // Prismic.Predicates.at('document.type', 'projects'),
      Prismic.Predicates.at('my.projects.programs.program', 'YD5C-REAAB8AKO7Q'),
    ];
    let pageFixed = 1;
    if (page !== undefined) pageFixed = page;
    client.query(
      predicates,
      {
        pageSize: 50,
        page: pageFixed,
        orderings: '[last_publication_date desc]',
        fetchLinks: ['locations.name', 'people.name'],
      },
    ).then((res) => {
      setEntries(res.results);
      // setTotalPages(res.total_pages);
      // console.log(res.results[0]);
    });
  };
  let pageParam = 1;
  if (match.params.page !== undefined) pageParam = match.params.page;

  useEffect(() => {
    // console.log(match.params);
    getEntries({
      page: pageParam,
    });
  }, [pageParam]);

  useEffect(() => {
    fetch('https://x8o5a6umlc.execute-api.us-east-1.amazonaws.com/dev/star-registry')
      .then((res) => res.json())
      .then((json) => {
        setArtists(json.artistRegistry);
      });
  }, []);
  // console.log(artists);
  return (
    <>
      <h1>STAR Sites</h1>
      <Row>
        {entries.map((entry, e) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col md={4} key={e}>
            <div className="film_entry">
              <Link to={`/films/detail/${entry.uid}`}>
                {entry.data.image.url !== undefined
                  ? (
                    <img
                      src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=480&h=270`}
                      alt={RichText.asText(entry.data.title)}
                    />
                  )
                  : null}
                {RichText.asText(entry.data.title)}
              </Link>
            </div>
          </Col>
        ))}
      </Row>
      <h2>Artist Registry</h2>
      <p>Partner with the following artists to display their work in your store front</p>
      <Row>
        {artists.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={i}>
            <div>{row.nameOfArtistOrCollective.answer}</div>
            <div>
              {row.boroughOfResidencyOrStudio.answer}
              {': '}
              {row[`neighborhoodOfResidencyOrStudio (${camelcase(row.boroughOfResidencyOrStudio.answer)})`].answer}
            </div>
            <div>
              <img src={`${row['headshotOrArtCollectiveLogo/image'].answer[0]}?width=200&height=200`} alt="headshot" />
            </div>
            <div>
              {row.imagesOfPastWork.answer.map((img) => (
                <img src={`${img}?height=200`} alt="past work" key={img} />
              ))}
            </div>
            <div>
              Links
              <ul>
                <li><a href={row.instagramUrl.answer}>Instagram</a></li>
                <li><a href={row['resume /Cv'].answer[0]}>Resume / CV</a></li>
                <li><a href={row.urlToVideoOfPastWork.answer}>Video of Past Work</a></li>
                <li><a href={row.websiteUrl.answer}>Website</a></li>
              </ul>
            </div>
            <div>{row.primaryArtisticDisciplineForStar.answer}</div>
          </Col>
        ))}
      </Row>
    </>
  );
};

FilmAllStar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      program: PropTypes.string,
      year: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

FilmAllStar.defaultProps = {
  match: {
    params: {},
  },
};

export default FilmAllStar;
