import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';

import { APIENDPOINT } from '../../config/constant';

import Slider from '../../Common/Slider';
import { trimPrismicUrl } from '../../helpers/prismic';

class SliceByEvents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const { slice, programId } = this.props;

    const predicates = [
      Prismic.Predicates.at('document.type', 'events'),
      Prismic.Predicates.at('my.events.programs.program', programId),
    ];
    switch (slice.primary.event_type) {
      case 'Past':
        predicates.push(Prismic.Predicates.dateBefore('my.events.dates.start', moment().toDate()));
        break;
      default:
        predicates.push(Prismic.Predicates.dateAfter('my.events.dates.start', moment().toDate()));
    }
    const client = Prismic.client(APIENDPOINT);
    client.query(
      predicates,
      { fetchLinks: [] },
    ).then((res) => {
      this.setState({
        entries: res.results,
      });
      // console.log(res.results);
    });
  }

  render() {
    const { entries } = this.state;
    const { slice } = this.props;
    // console.log(slice);
    const slides = [];
    const slidesToShow = 3;

    entries.forEach((entry) => {
      slides.push({
        uid: entry.uid,
        title: RichText.asText(entry.data.title),
        image: `${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=640&h=360`,
        url: `/events/detail/${entry.uid}`,
      });
    });

    const title = RichText.asText(slice.primary.slice_title);
    return (
      <Slider
        slides={slides}
        title={title}
        slidesToShow={slidesToShow}
      />
    );
  }
}

SliceByEvents.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      event_type: PropTypes.string,
      slice_title: PropTypes.arrayOf(PropTypes.object),
      /*
      slice_title_custom: PropTypes.arrayOf(PropTypes.object),
      year: PropTypes.string,
      type: PropTypes.string,
      action_url: PropTypes.string,
      action_label: PropTypes.string,
      */
    }),
  }),
  programId: PropTypes.string.isRequired,
};

SliceByEvents.defaultProps = {
  slice: {
    primary: {
      slice_title_custom: [],
      slice_title: '',
      year: '',
      type: '',
      action_url: '',
      action_label: '',
    },
  },
};
export default SliceByEvents;
