import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Drawer from 'rc-drawer';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Navbar, Nav,
} from 'react-bootstrap';

class GlobalNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: '',
      redirect: '',
    };
    this.onNavEnter = this.onNavEnter.bind(this);
    this.onNavLeave = this.onNavLeave.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
  }

  onNavEnter() {
    this.setState({ open: true });
  }

  onNavLeave() {
    this.setState({ open: false });
  }

  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  onSearchKeyUp(event) {
    const { search } = this.state;
    if (event.keyCode === 13 && search !== '') {
      this.setState({
        redirect: `/search/${encodeURIComponent(search)}`,
      });
    }
  }

  render() {
    const { open, search, redirect } = this.state;
    const menu = [
      {
        label: 'Home',
        url: '/',
        icon: 'home',
      },
      {
        label: 'Filmmaker Programs',
        url: '/program/filmmaker',
        icon: 'clapper',
      },
      {
        label: '19 Years of TFI',
        url: '/timeline',
        icon: 'people',
        highlight: true,
      },
      {
        label: 'Community Engagement',
        url: '/program/community',
        icon: 'ny',
      },
      /*
      {
        label: 'Support Storytellers',
        url: '/support',
        icon: 'heart',
        highlight: true,
      },
      */
    ];
    return (
      <>
        {redirect !== '' ? <Redirect to={redirect} /> : null}

        <Navbar expand="md" className="d-md-none d-lg-none d-xl-none">
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src="/img/tfi_k.png" alt="Tribeca Film Institute" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {menu.map((m) => (
                <LinkContainer key={m.label} to={m.url}>
                  <Nav.Link>
                    <span className={`noun icon-${m.icon}`} />
                    {m.label}
                  </Nav.Link>
                </LinkContainer>
              ))}
              <Navbar.Text>
                <span className="noun icon-search" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={this.onSearchChange}
                  onKeyUp={this.onSearchKeyUp}
                />
              </Navbar.Text>

            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div
          className="global-nav-level"
        />
        <Drawer
          placement="right"
          level="global-nav"
          showMask={false}
          open={open}
          className="d-none d-md-block d-lg-block d-xl-block"
        >
          <div
            onMouseEnter={this.onNavEnter}
            onMouseLeave={this.onNavLeave}
          >
            <ul className="global-nav desktop">
              {menu.map((m) => (
                <li key={m.label} className={m.highlight ? 'highlight' : ''}>
                  <Link to={m.url}>
                    <span className={`noun icon-${m.icon}`} />
                    {m.label}
                  </Link>
                </li>
              ))}
              <li>
                <span className="noun icon-search" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={this.onSearchChange}
                  onKeyUp={this.onSearchKeyUp}
                />
              </li>
            </ul>
          </div>
        </Drawer>
      </>
    );
  }
}

export default GlobalNav;
