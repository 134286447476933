/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSlick from 'react-slick';
import { Link } from 'react-router-dom';

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.reactSlick = React.createRef();
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
  }

  handleNextClick() {
    this.reactSlick.current.slickNext();
  }

  handlePrevClick() {
    this.reactSlick.current.slickPrev();
  }

  render() {
    const {
      slides,
      title,
      actionButton,
      slidesToShow,
      slidesToShowSm,
    } = this.props;
    // console.log(this.props);
    return (
      <div className="slider">
        <div className="desktop d-none d-md-block">
          <div className="slider-nav">
            <button className="prev" type="button" onClick={this.handlePrevClick}><i className="fas fa-angle-left" /></button>
            <button className="next" type="button" onClick={this.handleNextClick}><i className="fas fa-angle-right" /></button>
          </div>
          <div className="bg-wrapper">
            <h2>{title}</h2>
            <ReactSlick
              autoplay={false}
              infinite={false}
              slidesToShow={slidesToShow}
              arrows={false}
              ref={this.reactSlick}
              responsive={[
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: slidesToShowSm,
                  },
                },
              ]}
            >
              {slides.map((item) => (
                <div key={item.uid} className={`slide ${item.slideClass !== undefined ? item.slideClass : ''}`}>
                  {item.badge !== undefined ? (item.badge) : null}
                  <Link to={item.url}>
                    <img
                      src={item.image}
                      alt={item.title}
                    />
                  </Link>
                  <p>
                    <Link to={item.url}>
                      {item.title}
                      {item.titleNote !== ''
                        && item.titleNote !== undefined
                        && typeof item.titleNote !== 'object'
                        ? (
                          <span className="note">
                            {` (${item.titleNote})`}
                          </span>
                        ) : null}
                    </Link>
                  </p>
                  {typeof item.titleNote === 'object' ? (
                    <div className="title-note">
                      {item.titleNote}
                    </div>
                  ) : null}

                  {item.button !== undefined
                    ? <div><Link to={item.url} className="as-button small black">{item.button}</Link></div>
                    : null }
                </div>
              ))}
            </ReactSlick>
          </div>
          {actionButton.url !== '' && actionButton.url !== null && actionButton.url !== undefined
            ? <div className="action"><Link to={actionButton.url} className="as-button black">{actionButton.label}</Link></div>
            : null}
        </div>
        <div className="mobile d-md-none d-lg-none d-xl-none">
          <h2>{title}</h2>
          {slides.map((item) => (
            <div key={item.uid} className={`slide ${item.slideClass !== undefined ? item.slideClass : ''}`}>
              {item.badge !== undefined ? (item.badge) : null}
              <Link to={item.url}>
                <img
                  className={item.imgClass}
                  src={item.image}
                  alt={item.title}
                />
              </Link>
              <p>
                <Link to={item.url}>
                  {item.title}
                  {item.titleNote !== ''
                    && item.titleNote !== undefined
                    && typeof item.titleNote !== 'object'
                    ? (
                      <span className="note">
                        {` (${item.titleNote})`}
                      </span>
                    ) : null}
                </Link>
              </p>
              {typeof item.titleNote === 'object' ? (
                <>
                  {item.titleNote}
                </>
              ) : null}
              {item.button !== undefined
                ? <div><Link to={item.url} className="as-button small black">{item.button}</Link></div>
                : null }
            </div>
          ))}
          {actionButton.url !== '' && actionButton.url !== null && actionButton.url !== undefined
            ? <div><Link to={actionButton.url} className="as-button action">{actionButton.label}</Link></div>
            : null}
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  slidesToShow: PropTypes.number,
  slidesToShowSm: PropTypes.number,
  actionButton: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
};

Slider.defaultProps = {
  slidesToShow: 3,
  slidesToShowSm: 2,
  slides: [],
  title: '',
  actionButton: {
    url: '',
    label: '',
  },
};

export default Slider;
