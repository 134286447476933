/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import {
  Row, Col,
} from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';

class FilmAll extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      totalPages: 1,
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // console.log(match.params);
    this.getEntries({
      page: match.params.page,
    });
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.getEntries({
        page: match.params.page,
      });
    }
  }

  getEntries({
    page,
  }) {
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      Prismic.Predicates.at('document.type', 'projects'),
    ];
    let pageFixed = 1;
    if (page !== undefined) pageFixed = page;
    client.query(
      predicates,
      {
        pageSize: 50,
        page: pageFixed,
        fetchLinks: ['programs.title'],
        orderings: '[last_publication_date desc]',
      },
    ).then((res) => {
      this.setState({
        entries: res.results,
        totalPages: res.total_pages,
      });
      console.log(res.results[0]);
    });
  }

  render() {
    const { entries, totalPages } = this.state;
    let pageParam = 1;
    const { match } = this.props;
    if (match.params.page !== undefined) pageParam = match.params.page;
    return (
      <>
        <h1>All Projects</h1>
        <Row>
          {entries.map((entry, e) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col md={4} key={e}>
              <div className="film_entry">
                <Link to={`/films/detail/${entry.uid}`}>
                  {entry.data.image.url !== undefined
                    ? (
                      <img
                        src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=480&h=270`}
                        alt={RichText.asText(entry.data.title)}
                      />
                    )
                    : null
                  }
                  {RichText.asText(entry.data.title)}
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        {/**
        <h2>Missing Programs</h2>
        {entries.map((entry, e) => (
          <React.Fragment key={e}>
            {hasPrograms.indexOf(entry.id) === -1
              ? (
                <p>
                  <Link to={`/filmmakers/detail/${entry.uid}`}>
                    {RichText.asText(entry.data.name)}
                  </Link>
                </p>
              ) : null
            }
          </React.Fragment>
        ))}
         */}
        <div className="page-nav">
          {pageParam !== 1
            ? <Link to={`/films/all/${parseInt(pageParam, 10) - 1}`}>Prev Page</Link>
            : null
          }
          {` ${pageParam} `}
          of
          {` ${totalPages} `}
          {parseInt(pageParam, 10) !== parseInt(totalPages, 10)
            ? <Link to={`/films/all/${parseInt(pageParam, 10) + 1}`}>Next Page</Link>
            : null
          }
        </div>
      </>
    );
  }
}

FilmAll.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      program: PropTypes.string,
      year: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

FilmAll.defaultProps = {
  match: {
    params: {},
  },
};

export default FilmAll;
