export const APIENDPOINT = 'https://tribeca-film-institute.prismic.io/api/v2';

export const SITE_URL = 'https://www.tfiny.org';

export const SITE_NAME = 'Tribeca Film Institute';
export const SITE_DESCRIPTION = 'Tribeca Film Institute champions storytellers to be catalysts for change in their communities and around the world. ';
export const SITE_IMAGE = 'https://images.prismic.io/tribeca-film-institute/4c2bca9e-b697-4b43-8a88-263e67c6a138_tfi_k.png?auto=compress,format';

export const FILM_PLACEHOLDER_URL = 'https://images.prismic.io/tribeca-film-institute/fa676840-8951-4a58-951a-7899f13fce2e_TFIfilmplaceholder.jpg?auto=compress,format';

export const GOOGLE_MAP_API = 'AIzaSyAPWlCHyI5puVeTvf8ljGI-Lv3pja1ztm8';
