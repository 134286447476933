/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { APIENDPOINT } from '../config/constant';
import DisplayDeadline from './common/DisplayDeadline';
import GlobalFooter from '../Common/GlobalFooter';
import SliceByYear from './common/SliceByYear';
import SliceByEvents from './common/SliceByEvents';
import SliceByEntryIds from './common/SliceByEntryIds';
import SliceByProgramEvents from './common/SliceByProgramEvents';
import SliceByBlogPosts from './common/SliceByBlogPosts';

class ProgramDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      program: {},
      mode: '',
      programEvents: [],
      currentKP: '',
    };
    this.getPrismic = this.getPrismic.bind(this);
    this.handleKPClick = this.handleKPClick.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // console.log(match);
    this.getPrismic({
      uid: match.params.uid,
      programEventUid: match.params.programEventUid,
    });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      (prevProps.match.params.uid !== match.params.uid)
      || (prevProps.match.params.programEventUid !== match.params.programEventUid)
    ) {
      this.getPrismic({
        uid: match.params.uid,
        programEventUid: match.params.programEventUid,
      });
    }
  }

  getPrismic({ uid, programEventUid }) {
    const client = Prismic.client(APIENDPOINT);
    // console.log({ uid, programEventUid });
    if (uid !== undefined) {
      client.query(
        Prismic.Predicates.at('my.programs.uid', uid),
      ).then((res) => {
        let currentKP = '';
        if (res.results[0] && res.results[0].data.key_points[0] !== undefined) {
          currentKP = res.results[0].data.key_points[0].heading;
        }
        this.setState({
          program: res.results[0],
          mode: 'program',
          currentKP,
        });

        client.query([
          Prismic.Predicates.at('my.program_events.program', res.results[0].id),
          Prismic.Predicates.dateAfter('my.program_events.close', moment().toDate()),
        ]).then((res2) => {
          // console.log(res2);
          this.setState({
            programEvents: res2.results,
          });
        });
      });
    } else if (programEventUid !== undefined) {
      client.query(
        Prismic.Predicates.at('my.program_events.uid', programEventUid),
        { fetchLinks: [] },
      ).then((res) => {
        let currentKP = '';
        if (res.results[0].data.key_points[0] !== undefined) {
          currentKP = res.results[0].data.key_points[0].heading;
        }
        this.setState({
          programEvent: res.results[0],
          mode: 'programEvent',
          programEvents: [],
          currentKP,
        });
      });
    }
  }

  handleKPClick(kp) {
    const { currentKP } = this.state;
    if (currentKP === kp) {
      this.setState({ currentKP: '' });
    } else {
      this.setState({ currentKP: kp });
    }
  }

  render() {
    const { match } = this.props;
    const {
      programEvent,
      program,
      currentKP,
      programEvents,
      mode,
    } = this.state;
    // console.log(programEvents);

    const style = {
      backgroundColor: '#6FAFDB',
    };

    let entryData = {
      background_color: '',
      key_points: [],
      sponsors: [],
      image: {},
      title: [],
      hint: [],
      body: [],
    };

    if (mode === 'program') {
      entryData = program.data;
    } else if (mode === 'programEvent') {
      entryData = programEvent.data;
    }

    if (
      entryData.background_color !== ''
    ) style.backgroundColor = entryData.background_color;

    const kpToShow = [];
    entryData.key_points.forEach((kp) => {
      if (kp.heading !== '' && RichText.asText(kp.info) !== '') kpToShow.push(kp);
    });

    const sponsorToShow = [];
    entryData.sponsors.forEach((sponsor) => {
      if (sponsor.sponsor_logo.url !== undefined) sponsorToShow.push(sponsor);
    });

    // console.log(entry.data.deadlines);
    // console.log(entry);
    return (
      <div className={`program-detail ${match.params.uid}`}>
        <div className="hero" style={style}>
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <div className="table-wrapper">
              <div className="cell-wrapper">
                <Row>
                  <Col md={{ span: 8, offset: 4 }}>
                    <div className="program-logo">
                      {entryData.image.url !== undefined
                        ? <img src={`${entryData.image.url}&w=500&h=200`} alt={RichText.asText(entryData.title)} />
                        : null }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col md={4}>
              {entryData.close !== null && moment(entryData.close).unix() > moment().unix()
                ? (
                  <div className="deadlines">
                    <h3>Entry Deadline</h3>
                    <DisplayDeadline program={{ data: entryData }} directLink />
                  </div>
                ) : null}
              {entryData.event_date !== null
              && moment(entryData.event_date).unix() > moment().unix()
                ? (
                  <div className="deadlines">
                    <h3>{`Event Date: ${moment(entryData.event_date).format('YYYY-MM-DD')}`}</h3>
                    {RichText.render(entryData.event_teaser)}
                  </div>
                ) : null}

              {programEvents.map((pe) => (
                <div className="deadlines" key={pe.id}>
                  <h3>Entry Deadline</h3>
                  <DisplayDeadline program={pe} showLogo />
                </div>
              ))}
              {entryData.hint.length > 0
                ? (
                  <div className="sidebar-note">
                    {RichText.render(entryData.hint)}
                  </div>
                ) : null }
            </Col>
            <Col>
              {entryData.video !== undefined && entryData.video.html !== undefined
                ? <div dangerouslySetInnerHTML={{ __html: entryData.video.html }} className="program-video" />
                : null}
              <h1>{RichText.asText(entryData.title)}</h1>
              {kpToShow.map((kp) => (
                <div className="keypoints" key={kp.heading}>
                  <button type="button" onClick={() => this.handleKPClick(kp.heading)} className="as-text heading">
                    <span className={`icon-${currentKP === kp.heading ? 'minus' : 'plus'}`} />
                    {' '}
                    {kp.heading}
                  </button>
                  <div className={`info ${currentKP === kp.heading ? 'active' : ''}`}>{RichText.render(kp.info)}</div>
                </div>
              ))}
            </Col>
          </Row>
          {entryData.body.map((slice, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={`slice ${slice.slice_type}`}>
              {mode === 'program'
                ? <DisplaySlice slice={slice} programId={program.id} />
                : <DisplaySlice slice={slice} programId={programEvent.data.program.id} />}
            </div>
          ))}
          {sponsorToShow.length > 0
            ? (
              <div className="funder">
                <h2>{RichText.asText(entryData.sponsor_title)}</h2>
                {entryData.sponsors.map((sponsor, k) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row className="justify-content-md-center" key={k}>
                    <Col md={3}>
                      <a href={sponsor.sponsor_url.url}>
                        <img src={sponsor.sponsor_logo.url} alt={sponsor.sponsor_label} />
                      </a>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : null }
        </Container>
        <GlobalFooter />
      </div>
    );
  }
}

ProgramDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
      programEventUid: PropTypes.string,
    }),
  }),
};

ProgramDetail.defaultProps = {
  match: {
    params: {},
  },
};
export default ProgramDetail;

function DisplaySlice(props) {
  const { slice, programId } = props;
  // console.log(slice);
  switch (slice.slice_type) {
    case 'by_year':
      return (<SliceByYear slice={slice} programId={programId} />);
    case 'specific_entries':
      return (<SliceByEntryIds slice={slice} programId={programId} />);
    case 'events':
      return (<SliceByEvents slice={slice} programId={programId} />);
    case 'program_events':
      return (<SliceByProgramEvents slice={slice} programId={programId} />);
    case 'blog_entries':
      return (<SliceByBlogPosts slice={slice} programId={programId} />);
    default:
      // eslint-disable-next-line no-console
      console.log(slice.slice_type);
      return (
        <div />
      );
  }
}

DisplaySlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
  }),
  programId: PropTypes.string.isRequired,
};

DisplaySlice.defaultProps = {
  slice: {
    slice_type: '',
  },
};
