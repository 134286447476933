import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import GlobalFooter from '../Common/GlobalFooter';

class ProgramEventAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      program: {
        data: {
          title: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.getPrismic(match.params.uid);
  }

  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('document.type', 'program_events'),
      {
        orderings: '[my.program_events.event_date desc]',
      },
    ).then((res) => {
      // console.log(res.results);
      this.setState({
        entries: res.results,
      });
    });
    client.query(
      Prismic.Predicates.at('document.id', uid),
    ).then((res) => {
      // console.log(res.results);
      this.setState({
        program: res.results[0],
      });
    });
  }

  render() {
    const { entries, program } = this.state;
    const entriesByYear = {};
    entries.forEach((entry) => {
      const year = moment(entry.data.event_date).format('YYYY');
      if (entriesByYear[year] === undefined) entriesByYear[year] = [];
      entriesByYear[year].push(entry);
    });
    // console.log(entriesByYear);
    const years = Object.keys(entriesByYear).sort((a, b) => {
      if (parseInt(a, 10) > parseInt(b, 10)) return -1;
      if (parseInt(b, 10) > parseInt(a, 10)) return 1;
      return 0;
    });
    return (
      <div className="program event all">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>{RichText.asText(program.data.title)}</h1>
            {years.map((year) => (
              <div key={year}>
                <h2>{year}</h2>
                <Row>
                  {entriesByYear[year].map((entry) => (
                    <Col xl={3} lg={4} md={6} key={entry.id}>
                      <div className="event-col">
                        <Link to={moment(entry.data.event_date).unix() >= moment().unix() ? `/program/event/${entry.uid}` : `/program/event/summary/${entry.uid}`}>
                          <img
                            src={`${trimPrismicUrl(entry.data.listing_logo.url)}&fit=crop&w=640&h=360`}
                            alt={RichText.asText(entry.data.title)}
                          />
                          <div>{RichText.asText(entry.data.title)}</div>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

ProgramEventAll.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};
ProgramEventAll.defaultProps = {
  match: {
    params: {},
  },
};

export default ProgramEventAll;
