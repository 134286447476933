/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Container } from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import GlobalFooter from '../Common/GlobalFooter';
import { trimPrismicUrl } from '../helpers/prismic';

class PressDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          body: [],
          images: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.getPrismic(match.params.uid);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.uid !== match.params.uid) {
      this.getPrismic(match.params.uid);
    }
  }


  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      Prismic.Predicates.at('my.press_releases.uid', uid),
    ];
    client.query(
      predicates,
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      // console.log(res);
    });
  }

  render() {
    const {
      entry,
    } = this.state;
    // console.log(entry);

    return (
      <div className="press index">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>{RichText.asText(entry.data.title)}</h1>
            <div className="press-body">
              <p>{moment(entry.data.date).format('YYYY.MM.DD')}</p>
              {RichText.render(entry.data.body)}
            </div>
            <Row>
              {entry.data.images.map((image, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={i} lg={3} md={4} className="image-col">
                  <a href={trimPrismicUrl(image.image.url)}>
                    <img src={`${trimPrismicUrl(image.image.url)}&w=250&h=250`} alt="Press" />
                  </a>
                  {image.photo_credit !== ''
                    ? (
                      <div className="photo-credit">
                        <b>Photo Credit: </b>
                        {image.photo_credit}
                      </div>
                    ) : ''}
                  <div className="photo-description">{image.description}</div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

PressDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};

PressDetail.defaultProps = {
  match: {
    params: {},
  },
};

export default PressDetail;
