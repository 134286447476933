import React from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { APIENDPOINT } from '../config/constant';

class TopBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          image: {},
          body: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { uid } = this.props;
    this.getPrismic(uid);
  }

  // eslint-disable-next-line class-methods-use-this
  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.top_banners.uid', uid),
      { fetchLinks: [] },
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      console.log(res);
    });
  }

  render() {
    const { entry } = this.state;
    const style = {};
    if (entry.data.image.url !== undefined) {
      style.backgroundImage = `url(${entry.data.image.url}&w=1400)`;
    }
    return (
      <div className="top-banner">
        <div className="hero-bg" style={style}>
          <div className="hero-fade">
            <Container>
              <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
                <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
              </div>
              <h1>{RichText.asText(entry.data.title)}</h1>
              <div className="hero-body">
                <Row>
                  {entry.data.body.map((slice, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col key={i} md={slice.primary.col !== '' ? slice.primary.col : 12}>
                      {RichText.render(slice.primary.tagline)}
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>
        </div>
        {entry.data.image.alt !== ''
          ? <Container><div className="hero-photo-credit">{entry.data.image.alt}</div></Container>
          : null }
      </div>
    );
  }
}

TopBanner.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default TopBanner;
