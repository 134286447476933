/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { RichText } from 'prismic-reactjs';
import {
  Row, Col, Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import qrcode from 'qrcode';
import camelcase from 'camelcase';
import PropTypes from 'prop-types';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { trimPrismicUrl } from '../../helpers/prismic';

const ProgramStarProjectDetail = ({
  projectId,
  setProjectId,
  projects,
  projectRelatedEntries,
  badge,
}) => {
  if (
    projectId === null
    || projects.length === 0
    || Object.keys(projectRelatedEntries).length === 0
  ) return null;
  let project = {};
  projects.forEach((row) => {
    if (row.uid === projectId) {
      project = row;
    }
  });
  const renderLocation = (locationId) => {
    if (projectRelatedEntries[locationId]) {
      // console.log(projectRelatedEntries[locationId]);
      const location = projectRelatedEntries[locationId];
      // console.log(location);
      return (
        <div>
          <img
            src={`${trimPrismicUrl(location.data.building_image.url)}&fit=crop&w=800&h=450`}
            alt="project"
          />
          <div>
            <h3>{RichText.asText(location.data.name)}</h3>
          </div>
          <div>{location.data.bid}</div>
          <div>
            {location.data.listing_url.url ? (
              <a href={location.data.listing_url.url}>Available for lease</a>
            ) : 'Available for lease'}
          </div>
        </div>
      );
    }
    return null;
  };
  const renderArtist = (artistId) => {
    if (projectRelatedEntries[artistId]) {
      // console.log(projectRelatedEntries[locationId]);
      const artist = projectRelatedEntries[artistId];
      // console.log(artist);
      return (
        <div>
          <img
            src={`${trimPrismicUrl(artist.data.headshot.url)}&fit=crop&w=200&h=200`}
            alt="headshot"
            className="headshot"
          />
          <h3>{RichText.asText(artist.data.name)}</h3>
          {RichText.render(artist.data.bio)}
        </div>
      );
    }
    return null;
  };
  let dataQRcode = '';
  qrcode.toDataURL(`https://tfiny.org/star/${project.uid}`, (err, url) => {
    dataQRcode = url;
  });

  // console.log(project);
  return (
    <Modal
      show={projectId !== null}
      size="lg"
      onHide={() => setProjectId(null)}
      className="star-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            {RichText.asText(project.data.title)}
            {project.data.showing.map((show) => (
              <div key={show.start}>
                <span className="fas fa-map-marker-alt" />
                {' '}
                {RichText.asText(show.location.data.name)}
                <br />
                {/**
                <span className="fas fa-calendar" />
                {' '}
                {moment(show.start).format('M/D')}
                {' '}
                */}
                {badge[project.id] ? <div className={`showing-status ${camelcase(badge[project.id])}`}>{badge[project.id]}</div> : null}
              </div>
            ))}
          </div>
          <div className="title-links">
            <span>
              <Link to={`/star/${project.uid}`}>
                <span className="fas fa-link" />
                {' '}
                Permalink
              </Link>
            </span>
            {' '}
            <span>
              <a href={dataQRcode}>
                <span className="fas fa-qrcode" />
                {' '}
                QR Code
              </a>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            src={`${trimPrismicUrl(project.data.image.url)}&w=800`}
            alt="project"
            className="site-image"
          />
          <Row>
            <Col lg={8}>
              <div>
                {RichText.render(project.data.synopsis)}
              </div>
              {project.data.credits.map((credit) => (
                <div key={credit.people.id}>
                  {renderArtist(credit.people.id)}
                </div>
              ))}
            </Col>
            <Col lg={4}>
              {project.data.showing.map((show) => (
                <div key={show.location.id} className="showing-div">
                  {renderLocation(show.location.id)}
                </div>
              ))}
              {project.data.sponsor_logos.map(((logoRow) => (
                <div className="project-sponsor-logo">
                  {logoRow.sponsor_logo.url !== null ? (
                    <a href={logoRow.sponsor_url} target="_blank" rel="noopener noreferrer">
                      <img src={`${trimPrismicUrl(logoRow.sponsor_logo.url)}&w=250`} alt="sponors" />
                    </a>
                  ) : null}
                </div>
              )))}
            </Col>
          </Row>
          {project.data.gallery ? (
            <div>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry
                  gutter={15}
                >
                  {project.data.gallery.map((galleryRow, gi) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={gi} className="gallery-image">
                      <img
                        src={`${trimPrismicUrl(galleryRow.gallery_image.url)}&w=500`}
                        alt="project-gallery"
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
              <div>{project.data.gallery_photo_credit}</div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>

  );
};

ProgramStarProjectDetail.propTypes = {
  projectId: PropTypes.string,
  setProjectId: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object),
  projectRelatedEntries: PropTypes.object,
  badge: PropTypes.object,
};

ProgramStarProjectDetail.defaultProps = {
  projectId: null,
  projects: [],
  projectRelatedEntries: {},
  badge: {},
};

export default ProgramStarProjectDetail;
