import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { APIENDPOINT, SITE_URL } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import GlobalFooter from '../Common/GlobalFooter';
import SocialShareOut from '../Common/SocialShareOut';

class EventIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          description: [],
          image: {},
          dates: [],
        },
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.getPrismic(match.params.uid);
  }

  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      Prismic.Predicates.at('my.events.uid', uid),
    ];
    client.query(
      predicates,
    ).then((res) => {
      // console.log(res);
      this.setState({
        entry: res.results[0],
      });
    });
  }

  showDates() {
    const { entry } = this.state;
    const datesToShow = [];
    entry.data.dates.forEach((date) => {
      const dateToShow = {};
      if (date.hide_the_time) {
        if (moment(date.start).format('YYYY-MM-DD') === moment(date.end).format('YYYY-MM-DD')) {
          // same day
          dateToShow.date = moment(date.start).format('YYYY/MM/DD');
        } else if (moment(date.start).format('YYYY-MM') === moment(date.end).format('YYYY-MM')) {
          // same month
          dateToShow.date = `${moment(date.start).format('YYYY/MM/DD')} - ${moment(date.end).format('DD')}`;
        } else if (moment(date.start).format('YYYY') === moment(date.end).format('YYYY')) {
          // same year
          dateToShow.date = `${moment(date.start).format('YYYY/MM/DD')} - ${moment(date.end).format('MM/DD')}`;
        } else {
          dateToShow.date = `${moment(date.start).format('YYYY/MM/DD')} - ${moment(date.end).format('YYYY/MM/DD')}`;
        }
      } else if (moment(date.start).format('YYYY-MM-DD h:mm') === moment(date.end).format('YYYY-MM-DD h:mm')) {
        // same day
        dateToShow.date = moment(date.start).format('YYYY/MM/DD h:mm');
      } else if (moment(date.start).format('YYYY-MM-DD') === moment(date.end).format('YYYY-MM-DD')) {
        // same day
        dateToShow.date = `${moment(date.start).format('YYYY/MM/DD h:mm')} - ${moment(date.end).format('h:mm')}`;
      } else if (moment(date.start).format('YYYY') === moment(date.end).format('YYYY')) {
        // same year
        dateToShow.date = `${moment(date.start).format('YYYY/MM/DD h:mm')} - ${moment(date.end).format('MM/DD h:mm')}`;
      } else {
        dateToShow.date = `${moment(date.start).format('YYYY/MM/DD h:mm')} - ${moment(date.end).format('YYYY/MM/DD h:mm')}`;
      }
      dateToShow.title = date.label;
      datesToShow.push(dateToShow);
    });
    return (
      <div className="event-date">
        {datesToShow.map((dateRow, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            {dateRow.title !== '' ? <div className="date-label">{dateRow.title}</div> : null}
            <div className="date-display">{dateRow.date}</div>
          </div>
        ))}
      </div>
    );
  }


  render() {
    const { entry } = this.state;

    return (
      <div className="events detail">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>{RichText.asText(entry.data.title)}</h1>
            {entry.data.image.url !== undefined
              ? (
                <img
                  src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=1152&h=648`}
                  alt={RichText.asText(entry.data.title)}
                  className="event-image"
                />
              ) : null}
            <Row>
              <Col lg={9}>
                {this.showDates()}
                <div className="event-body">
                  {RichText.render(entry.data.description)}
                </div>
              </Col>
              <Col>
                <SocialShareOut
                  url={`${SITE_URL}/events/detail/${entry.uid}`}
                  title={RichText.asText(entry.data.title)}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

EventIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};
EventIndex.defaultProps = {
  match: {
    params: {
      uid: '',
    },
  },
};

export default EventIndex;
