/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Switch from 'react-switch';
import { Row, Col } from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import BlogList from './common/BlogList';

class BlogIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      filters: [],
      totalPages: 1,
      uiFilter: {},
    };
    this.getBlogPosts = this.getBlogPosts.bind(this);
    this.getBlogFilters = this.getBlogFilters.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;
    this.getBlogPosts(pageParam);
    this.getBlogFilters();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    let needRefresh = false;
    if (prevProps.match.params.page !== match.params.page) needRefresh = true;
    if (prevProps.match.params.cat !== match.params.cat) needRefresh = true;

    if (needRefresh) {
      let pageParam = 1;
      if (match.params.page !== undefined) pageParam = match.params.page;
      this.getBlogPosts(pageParam);
      // this.getBlogFilters();
    }
  }

  getBlogFilters() {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      [
        Prismic.Predicates.at('document.type', 'blog_categories'),
        Prismic.Predicates.any('document.tags', ['filter']),
      ],
      {
        pageSize: 100,
        orderings: '[my.blog_categories.category]',
      },
    ).then((res) => {
      const uiFilter = {};
      res.results.forEach((filter) => {
        uiFilter[filter.id] = false;
      });
      this.setState({
        filters: res.results,
        uiFilter,
      });
      // console.log(res);
    });
  }

  getBlogPosts(page) {
    const { uiFilter } = this.state;
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      Prismic.Predicates.dateBefore('my.blogs.date', moment().format('YYYY-MM-DD')),
      Prismic.Predicates.at('document.type', 'blogs'),
    ];
    let noFilter = true;
    const filters = [];
    Object.keys(uiFilter).forEach((key) => {
      if (uiFilter[key]) {
        noFilter = false;
        filters.push(key);
        // Prismic.Predicates.at('my.blogs.categories.category', catRes.results[0].id),
      }
    });
    if (!noFilter) {
      filters.forEach((filterId) => {
        predicates.push(Prismic.Predicates.at('my.blogs.categories.category', filterId));
      });
    }
    client.query(
      predicates,
      {
        fetchLinks: ['blog_categories.category', 'blog_categories.category_full'],
        pageSize: 10,
        page,
        orderings: '[my.blogs.date desc]',
      },
    ).then((res) => {
      this.setState({
        blogs: res.results,
        totalPages: res.total_pages,
      });
      // console.log(res);
    });
  }

  handleFilterClick(filterId) {
    const { uiFilter } = this.state;
    uiFilter[filterId] = !uiFilter[filterId];
    this.setState({ uiFilter }, () => {
      const { match } = this.props;
      let pageParam = 1;
      if (match.params.page !== undefined) pageParam = match.params.page;
      this.getBlogPosts(pageParam);
    });
  }

  render() {
    const { match } = this.props;
    const {
      uiFilter,
      filters,
      blogs,
      totalPages,
    } = this.state;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;

    return (
      <div className="index">
        <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
          <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
        </div>
        <h1>Blog</h1>
        <Row>
          <Col lg={4}>
            <div className="filters">
              <h3>Filter By Category</h3>
              {filters.map((filter) => (
                <div className="filter-row" key={filter.id}>
                  <Switch
                    checked={uiFilter[filter.id]}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    offHandleColor="#aaaaaa"
                    handleDiameter={30}
                    checkedIcon={false}
                    height={25}
                    className="react-switch"
                    onChange={() => this.handleFilterClick(filter.id)}
                  />
                  <span className="label">{RichText.asText(filter.data.category)}</span>
                </div>
              ))}
            </div>
          </Col>
          <Col>
            {/**
            <blockquote className="big-quote">
              Tacos enamel pin bitters tousled, street art adaptogen schlitz wolf vegan squid.
              <footer>
                <cite>Name Name</cite>
              </footer>
            </blockquote>
             */}
          </Col>
        </Row>
        <h3 className="filter-heading">Filtered Results</h3>
        <BlogList blogs={blogs} filters={filters} />
        <div className="page-nav">
          {pageParam !== 1
            ? <Link to={`/blog/${parseInt(pageParam, 10) - 1}`}>&#x25c0; &nbsp;</Link>
            : null }

          {`${pageParam} of ${totalPages}`}

          {parseInt(pageParam, 10) !== parseInt(totalPages, 10)
            ? <Link to={`/blog/${parseInt(pageParam, 10) + 1}`}>&nbsp; &#x25b6;</Link>
            : null }
        </div>
      </div>
    );
  }
}

BlogIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cat: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
};

BlogIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default BlogIndex;
