import React from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Switch from 'react-switch';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import GlobalFooter from '../Common/GlobalFooter';

class EventIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      categories: [],
      uiCategories: {},
      now: moment(moment().format('YYYY-MM-01')),
      upcoming: true,
    };
    this.getPrismic = this.getPrismic.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
    this.getCategories();
  }

  getCategories() {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('document.type', 'event_categories'),
      {
        orderings: '[my.event_categories.category]',
      },
    ).then((res) => {
      const uiCategories = {};
      res.results.forEach((cat) => {
        uiCategories[cat.id] = true;
      });
      // console.log(res);
      this.setState({
        categories: res.results,
        uiCategories,
      });
    });
  }

  getPrismic() {
    const { now, upcoming, uiCategories } = this.state;
    const client = Prismic.client(APIENDPOINT);
    // const currentFirst = moment(moment().format('YYYY-MM-01'));
    const predicates = [
      Prismic.Predicates.at('document.type', 'events'),
    ];
    let orderings = '';
    if (upcoming) {
      // console.log(currentFirst.format('YYYY-MM-DD'));
      // console.log(now.format('YYYY-MM-DD'));
      // console.log('future');
      predicates.push(Prismic.Predicates.dateAfter('my.events.dates.start', now.toDate()));
      orderings = '[my.events.sort_date]';
    } else {
      // console.log('past');
      predicates.push(Prismic.Predicates.dateBefore('my.events.dates.start', now.toDate()));
      orderings = '[my.events.sort_date desc]';
    }

    let filterAll = true;
    const categoryIds = [];
    Object.keys(uiCategories).forEach((catId) => {
      if (uiCategories[catId] === false) {
        filterAll = false;
      } else {
        categoryIds.push(catId);
      }
    });
    if (filterAll === false && categoryIds.length > 0) {
      predicates.push(Prismic.Predicates.any('my.events.categories.category', categoryIds));
    }

    client.query(
      predicates,
      {
        orderings,
      },
    ).then((res) => {
      // console.log(res);
      this.setState({
        entries: res.results,
      });
    });
  }

  handleMonthClick(dir) {
    const { now } = this.state;
    let month = parseInt(now.format('M'), 10) + 1;
    let year = parseInt(now.format('YYYY'), 10);
    if (dir === 'prev') {
      month -= 2;
    }
    if (month === 0) {
      month = 12;
      year -= 1;
    } else if (month === 13) {
      month = 1;
      year += 1;
    }

    month = month.toString().padStart(2, '0');

    const currentFirst = moment(moment().format('YYYY-MM-01'));
    let newNow = moment(`${year}-${month}-01`);
    let upcoming = true;
    if (currentFirst.unix() > newNow.unix()) {
      const lastDay = newNow.daysInMonth();
      newNow = moment(`${year}-${month}-${lastDay}`);
      upcoming = false;
    }

    this.setState({ now: newNow, upcoming }, () => {
      this.getPrismic();
    });
  }

  handleCategoryClick(filterId) {
    const { uiCategories } = this.state;
    uiCategories[filterId] = !uiCategories[filterId];
    this.setState({ uiCategories }, () => {
      this.getPrismic();
    });
  }

  render() {
    const {
      entries,
      now,
      upcoming,
      categories,
      uiCategories,
    } = this.state;

    const days = [];
    const daysNeededFromBefore = parseInt(moment(now.format('YYYY-MM-01')).format('d'), 10) + 1;
    const calendarStart = moment(now.format('YYYY-MM-01')).subtract(daysNeededFromBefore, 'days');
    const totalDays = Math.ceil((daysNeededFromBefore + now.daysInMonth()) / 7) * 7;
    for (let i = 1; i <= totalDays; i += 1) {
      const dayToAdd = {
        momentDate: moment(calendarStart.add(1, 'd').format('YYYY-MM-DD')),
        class: 'day',
        events: [],
      };
      if (dayToAdd.momentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        dayToAdd.class = `${dayToAdd.class} today`;
      }
      if (dayToAdd.momentDate.format('MM') !== now.format('MM')) {
        dayToAdd.class = `${dayToAdd.class} other-month`;
      }
      entries.forEach((entry) => {
        entry.data.dates.forEach((dateRow) => {
          const startDateOnly = moment(moment(dateRow.start).format('YYYY-MM-DD'));
          const endDateOnly = moment(moment(dateRow.end).format('YYYY-MM-DD'));
          const dayToAddDateOnly = moment(dayToAdd.momentDate.format('YYYY-MM-DD'));
          if (
            startDateOnly.unix() <= dayToAddDateOnly.unix()
            && endDateOnly.unix() >= dayToAddDateOnly.unix()
          ) {
            // console.log('has event');
            dayToAdd.class = `${dayToAdd.class} has-event`;
            dayToAdd.events.push(RichText.asText(entry.data.title));
          }
        });
      });
      // console.log(dayToAdd);
      days.push(dayToAdd);
    }
    const entriesToShow = {};
    entries.forEach((entry) => {
      entry.data.dates.forEach((dateRow) => {
        let include = false;
        if (upcoming && moment(dateRow.start).unix() >= now.unix()) {
          include = true;
        } else if (!upcoming && moment(dateRow.start).unix() <= now.unix()) {
          include = true;
        }
        if (include) {
          const dateRowMonth = moment(dateRow.start).format('MMMM, YYYY');
          if (entriesToShow[dateRowMonth] === undefined) entriesToShow[dateRowMonth] = [];
          entriesToShow[dateRowMonth].push({
            data: entry.data,
            id: entry.id,
            uid: entry.uid,
            displayDate: moment(dateRow.start).format('MM.DD'),
          });
        }
      });
    });
    return (
      <div className="events index">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>Events</h1>
            <div className="filter-calendar">
              <Row>
                <Col lg={7} md={6} className="calendar">
                  <div>
                    <div className="cal-nav">
                      <button type="button" onClick={() => this.handleMonthClick('prev')}>
                        <i className="fas fa-angle-left" />
                      </button>
                      <button type="button" onClick={() => this.handleMonthClick('next')}>
                        <i className="fas fa-angle-right" />
                      </button>
                    </div>
                    <div className="month">{now.format('MMMM YYYY')}</div>
                  </div>
                  <div className="minical clearfix">
                    <div className="day header">Su</div>
                    <div className="day header">M</div>
                    <div className="day header">Tu</div>
                    <div className="day header">W</div>
                    <div className="day header">Th</div>
                    <div className="day header">F</div>
                    <div className="day header">Sa</div>
                    {days.map((d) => (
                      <div
                        key={d.momentDate.unix()}
                        className={d.class}
                      >
                        <div className="square">{d.momentDate.format('D')}</div>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col className="filter">
                  <h3>Filter by Category</h3>
                  {categories.map((category) => (
                    <div className="filter-row" key={category.id}>
                      <Switch
                        checked={uiCategories[category.id]}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        offHandleColor="#aaaaaa"
                        handleDiameter={30}
                        checkedIcon={false}
                        height={25}
                        className="react-switch"
                        onChange={() => this.handleCategoryClick(category.id)}
                      />
                      <span className="label">{RichText.asText(category.data.category)}</span>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
            {Object.keys(entriesToShow).map((month) => (
              <div key={month}>
                <h4 className="month-label">{month}</h4>
                <Row>
                  {entriesToShow[month].map((entry) => (
                    <Col lg={6} key={`${entry.id}${entry.displayDate}`} className="event-entry">
                      <div className="event-date">{entry.displayDate}</div>
                      <div>
                        {entry.data.image.url !== undefined
                          ? (
                            <Link to={`/events/detail/${entry.uid}`}>
                              <img
                                src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=640&h=360`}
                                alt={RichText.asText(entry.data.title)}
                                className="event-image"
                              />
                            </Link>
                          ) : null}
                      </div>
                      <h2><Link to={`/events/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
                      {/* <pre>{JSON.stringify(entry.data, undefined, 2)}</pre> */}
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
            {Object.keys(entriesToShow).length === 0
              ? <div className="no-results">Sorry, no events are found</div>
              : null }
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

EventIndex.propTypes = {
};
EventIndex.defaultProps = {
};

export default EventIndex;
