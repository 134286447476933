import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col,
} from 'react-bootstrap';

class GlobalFooter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formClick = this.formClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  formClick() {
    return 1;
  }

  render() {
    const social = [
      {
        icon: 'instagram',
        url: 'https://www.instagram.com/tribecafilmins',
      },
      {
        icon: 'twitter',
        url: 'https://twitter.com/TribecaFilmIns',
      },
      {
        icon: 'facebook',
        url: 'https://www.facebook.com/TribecaFilmInstitute/',
      },
      {
        icon: 'youtube',
        url: 'https://www.youtube.com/user/tribecafilminstitute',
      },
    ];
    const menu1 = [
      {
        label: 'Blog',
        url: '/blog',
      },
      {
        label: 'Press',
        url: '/press',
      },
      /*
      {
        label: 'Events',
        url: '/events',
      },
      {
        label: 'Contact',
        url: '/contact',
      },
      */
      {
        label: 'Archive',
        url: '/program/archive',
      },
    ];
    const menu2 = [
      {
        label: 'About TFI',
        url: '/about',
      },
      {
        label: 'Filmmaker Programs',
        url: '/program/filmmaker',
      },
      {
        label: 'Community Engagement',
        url: '/program/community',
      },
      /*
      {
        label: 'Featured Storytellers',
        url: '/storytellers',
      },
      {
        label: 'Team',
        url: '/team',
      },
      {
        label: 'Board',
        url: '/team',
      },
      {
        label: 'Careers',
        url: '/career',
      },
      {
        label: 'Donor Privacy Policy',
        url: '',
      },
      */
      /*
      {
        label: 'Support Storytellers',
        url: '/support',
        highlight: true,
      },
      {
        label: 'Feature Sponsors',
        url: '',
      },
      */
    ];
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col lg={{ span: 6, order: 4 }}>
              <form className="subscribe-form">
                <h3>Tribeca Film Festival</h3>
                <p>
                  Phone: 212.941.2400
                  <br />
                  E-mail: festival@tribecafilm.com
                </p>
                {/**
                <h3>Receive update stright to you inobx</h3>
                <Link
                  to="/newsletter"
                  className="as-button small blue"
                >Subscribe to Newsletter</Link>
                 */}
                {/**
                <input type="email" placeholder="Email" />
                <button type="submit" className="tfi-button small blue">Subscribe</button>
                 */}
              </form>
              <ul className="social-menu">
                {social.map((soc) => (
                  <li key={soc.icon}>
                    <a href={soc.url} target="_blank" rel="noopener noreferrer">
                      <i className={`fab fa-${soc.icon}`} />
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={{ span: 2, order: 1 }}>
              <ul className="menu">
                {menu1.map((mn) => (
                  <li key={mn.label}>
                    <Link to={mn.url} className={mn.highlight ? 'highlight' : ''}>{mn.label}</Link>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={{ span: 4, order: 2 }}>
              <ul className="menu">
                {menu2.map((mn) => (
                  <li key={mn.label}>
                    <Link to={mn.url} className={mn.highlight ? 'highlight' : ''}>{mn.label}</Link>
                  </li>
                ))}
              </ul>
            </Col>
            {/*
            <Col lg={{ span: 3, order: 3 }}>
              <ul className="menu">
                {menu3.map((mn) => (
                  <li key={mn.label}>
                    <Link to={mn.url} className={mn.highlight ? 'highlight' : ''}>{mn.label}</Link>
                  </li>
                ))}
              </ul>
            </Col>
            */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default GlobalFooter;
