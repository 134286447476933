import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import {
  APIENDPOINT, SITE_URL, SITE_NAME, SITE_IMAGE,
} from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import SocialShareOut from '../Common/SocialShareOut';

class FilmDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let uidParam = '';
    if (match.params.uid !== undefined) uidParam = match.params.uid;
    this.getEntries(uidParam);
  }

  getEntries(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.projects.uid', uid),
      { fetchLinks: ['people.name', 'people.headshot', 'people.bio', 'programs.title'] },
    ).then((res) => {
      this.setState({
        entries: res.results,
      });
      // console.log(res.results[0]);
    });
  }

  render() {
    const { entries } = this.state;
    // console.log(entries);
    return (
      <>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            {entries.map((entry, e) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={e}>
                <Helmet>
                  <title>{`${RichText.asText(entry.data.title)} - ${SITE_NAME}`}</title>
                  <meta name="description" content={RichText.asText(entry.data.logline)} />

                  <meta property="og:title" content={RichText.asText(entry.data.title)} />
                  <meta property="og:description" content={RichText.asText(entry.data.logline)} />

                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={entry.data.image.url ? entry.data.image.url : SITE_IMAGE} />
                </Helmet>

                <h1>{RichText.asText(entry.data.title)}</h1>
                {entry.data.video.html !== undefined
                  // eslint-disable-next-line react/no-danger
                  ? <div dangerouslySetInnerHTML={{ __html: entry.data.video.html }} className="film-video-top" />
                  : null}
                {entry.data.image.url !== undefined
                  ? (
                    <img
                      src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=960&h=540`}
                      alt={RichText.asText(entry.data.title)}
                      className="film-image-top"
                    />
                  ) : null}
                <Row>
                  <Col>
                    <div className="programs">
                      <h3>TFI Suppport</h3>
                      {entry.data.programs.map((program, p) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={p}>
                          {program.program.type !== 'broken_type' ? (
                            <p>
                              <Link to={`/programs/detail/${program.program.uid}`}>{RichText.asText(program.program.data.title)}</Link>
                              {' '}
                              {program.year}
                              {' '}
                              {program.round}
                              {' '}
                              {program.program_note}
                            </p>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="film-body">
                      <h2>Logline</h2>
                      {RichText.render(entry.data.logline)}
                    </div>
                    <div className="filmmaker-body">
                      {entry.data.credits.map((person, p) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={p}>
                          <Link to={`/filmmakers/detail/${person.people.uid}`}>
                            {person.people.data.headshot !== undefined
                              ? (
                                <img
                                  src={`${trimPrismicUrl(person.people.data.headshot.url)}&fit=crop&w=250&h=250`}
                                  alt={RichText.asText(entry.data.title)}
                                  className="headshot"
                                />
                              ) : null}
                          </Link>
                          <h2><Link to={`/filmmakers/detail/${person.people.uid}`}>{RichText.asText(person.people.data.name)}</Link></h2>
                          <p>{person.role}</p>
                          {RichText.render(person.people.data.bio)}
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="links">
                      {entry.data.links.map((link, l) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={l}>
                          <a href={link.url.url} target="_blank" rel="noopener noreferrer">{link.platform}</a>
                        </p>
                      ))}
                    </div>
                    <SocialShareOut
                      url={`${SITE_URL}/films/detail/${entry.uid}`}
                      title={RichText.asText(entry.data.title)}
                    />
                    {entry.data.study_guide && entry.data.study_guide.url
                      ? (
                        <div className="study-guide">
                          <a
                            href={entry.data.study_guide.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                            <br />
                            Study Guide
                            <br />
                            <img
                              alt="study guide"
                              src="https://images.prismic.io/tribeca-film-institute/f2422439-b82b-4b2d-8f1e-5e8d40533ae3_noun_Book_2173674.svg?auto=compress,format"
                            />
                          </a>
                        </div>
                      ) : null}
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </>
    );
  }
}

FilmDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
    }),
  }),
};

FilmDetail.defaultProps = {
  match: {
    params: {},
  },
};
export default FilmDetail;
