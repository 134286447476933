/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../config/constant';
import BlogList from './common/BlogList';

class BlogIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      cats: [],
      totalPages: 1,
    };
    this.getBlogPostsByCat = this.getBlogPostsByCat.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;
    this.getBlogPostsByCat(match.params.cat, pageParam);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    let needRefresh = false;
    if (prevProps.match.params.page !== match.params.page) needRefresh = true;
    if (prevProps.match.params.cat !== match.params.cat) needRefresh = true;

    if (needRefresh) {
      let pageParam = 1;
      if (match.params.page !== undefined) pageParam = match.params.page;
      this.getBlogPostsByCat(match.params.cat, pageParam);
    }
  }

  getBlogPostsByCat(cat, page) {
    const client = Prismic.client(APIENDPOINT);
    client.query([
      Prismic.Predicates.at('my.programs.uid', cat),
    ]).then((catRes) => {
      // console.log(catRes);
      if (catRes.results.length > 0) {
        client.query(
          [
            Prismic.Predicates.dateBefore('my.blogs.date', moment().format('YYYY-MM-DD')),
            Prismic.Predicates.at('document.type', 'blogs'),
            Prismic.Predicates.at('my.blogs.categories.category', catRes.results[0].id),
          ],
          {
            fetchLinks: ['blog_categories.category', 'blog_categories.category_full'],
            pageSize: 10,
            page,
            orderings: '[my.blogs.date desc]',
          },
        ).then((res) => {
          this.setState({
            blogs: res.results,
            totalPages: res.total_pages,
          });
          // console.log(res);
        });
        this.setState({
          cats: catRes.results,
        });
      }
      // console.log(catRes);
    });
  }

  render() {
    const { match } = this.props;
    const { cats, blogs, totalPages } = this.state;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;

    return (
      <div className="index">
        <h1>
          Blog
          {cats.map((cat, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={i}>
              :
              {' '}
              {RichText.asText(cat.data.title)}
            </span>
          ))}
        </h1>
        <BlogList blogs={blogs} />
        <div className="page-nav">
          {pageParam !== 1
            ? <Link to={`/blog/program/${match.params.cat}/${parseInt(pageParam, 10) - 1}`}>&#x25c0; &nbsp;</Link>
            : null
          }
          {`${pageParam} of ${totalPages}`}
          {parseInt(pageParam, 10) !== parseInt(totalPages, 10)
            ? <Link to={`/blog/program/${match.params.cat}/${parseInt(pageParam, 10) + 1}`}>&nbsp; &#x25b6;</Link>
            : null
          }
        </div>
      </div>
    );
  }
}

BlogIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cat: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
};

BlogIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default BlogIndex;
