import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { trimPrismicUrl } from '../../helpers/prismic';


class BlogList extends React.PureComponent {
  categoryToShow(cats) {
    const { filters } = this.props;
    // console.log(filters);
    const catsToShow = [];
    cats.forEach((cat) => {
      if (cat.category.id !== undefined && cat.category.data !== undefined) {
        filters.forEach((filter) => {
          const filterText = RichText.asText(filter.data.category);
          const categoryText = RichText.asText(cat.category.data.category);
          if (filterText === categoryText) {
            catsToShow.push(RichText.asText(cat.category.data.category));
          }
        });
      }
    });
    return (
      <span className="category">
        {catsToShow.join(', ')}
      </span>
    );
  }

  render() {
    const { blogs } = this.props;
    // console.log(blogs);
    return (
      <Row className="blog-list">
        {blogs.map((entry, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={i} lg={6} className="blog-entry">
            <div className="clearfix categories">
              <div className="date">{moment(entry.data.date).format('YYYY-MM-DD')}</div>
              {this.categoryToShow(entry.data.categories)}
            </div>
            {entry.data.video && entry.data.video.html
              // eslint-disable-next-line react/no-danger
              ? <div dangerouslySetInnerHTML={{ __html: entry.data.video.html }} className="blog-video" />
              : (
                <>
                  {entry.data.image && entry.data.image.w800 ? (
                    <Link to={`/blog/detail/${entry.uid}`}>
                      <img src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=960&h=540`} alt={RichText.asText(entry.data.title)} />
                    </Link>
                  ) : null }
                </>
              ) }
            <h2><Link to={`/blog/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
            <div>{RichText.render(entry.data.teaser)}</div>
            <Link to={`/blog/detail/${entry.uid}`} className="as-button lightblue">Learn More</Link>
          </Col>
        ))}
      </Row>
    );
  }
}

BlogList.propTypes = {
  blogs: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.string),
};

BlogList.defaultProps = {
  blogs: [],
  filters: [],
};

export default BlogList;
