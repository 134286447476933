import React, { useEffect, useState } from 'react';
import VideoCover from 'react-video-cover';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import Scroll from 'react-scroll-to-element';
// import ScrollAnimation from 'react-animate-on-scroll';

import GlobalFooter from './Common/GlobalFooter';
import { APIENDPOINT } from './config/constant';
import { trimPrismicUrl } from './helpers/prismic';

// const DELAY = 150;
// const OFFSET = 150;
// const DURATION = 2;

function renderYearSlice(slice, index) {
  return (
    <div key={index}>
      <Row className="year-row" id={`id${slice.primary.year}`}>
        <Col sm={3}>
          <h2>{slice.primary.year}</h2>
          <h3>{slice.primary.subtitle}</h3>
        </Col>
        <Col className="content">
          {RichText.render(slice.primary.content)}
        </Col>
      </Row>
    </div>
  );
}
function renderImagesSlice(slice, index) {
  return (
    <div key={index} className="image-row">
      <Row>
        {slice.items.map((image, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col md={image.col !== '' ? image.col : null} key={i}>
            <img src={`${trimPrismicUrl(image.image.url)}&fit=crop&w=900`} alt="gallery images" />
          </Col>
        ))}
      </Row>
    </div>
  );
}
function renderVideosSlice(slice, index) {
  return (
    <div key={index} className="video-row">
      {slice.items.map((video, i) => (
        // eslint-disable-next-line react/no-array-index-key, react/no-danger
        <div key={i} dangerouslySetInnerHTML={{ __html: video.video.html }} className="timeline-video" />
      ))}
    </div>
  );
}

function renderSlice(slice, index) {
  if (slice.slice_type === 'year') return renderYearSlice(slice, index);
  if (slice.slice_type === 'images') return renderImagesSlice(slice, index);
  if (slice.slice_type === 'videos') return renderVideosSlice(slice, index);
  return (<div key={index}>{slice.slice_type}</div>);
}

function Timeline() {
  const [slices, setSlices] = useState([]);
  const [vertLogo, setVertLogo] = useState('');
  const [heroImage, setHeroLogo] = useState('');
  const [heroVideUrl, setheroVideUrl] = useState('');
  useEffect(() => {
    Prismic.api(APIENDPOINT).then((api) => {
      api.query(
        Prismic.Predicates.at('document.type', 'timeline'),
        {
          fetchLinks: ['blogs.title', 'blogs.image'],
        },
      ).then((response) => {
        if (response) {
          // console.log(response.results[0]);
          if (response.results[0]) {
            setSlices(response.results[0].data.body);
            setVertLogo(response.results[0].data.vertical_logo.url);
            setHeroLogo(response.results[0].data.hero_image.url);
            setheroVideUrl(response.results[0].data.hero_video_url);
          }
        }
      });
    });
  }, []);
  // console.log(slices);
  const years = [];
  slices.forEach((slice) => {
    if (slice.slice_type === 'year') years.push(slice.primary.year);
  });
  return (
    <div className="timeline index">
      <div
        className="hero"
      >
        <Container>
          <div className="text">
            <img src={`${trimPrismicUrl(heroImage)}&w=600`} alt="TFI" className="hero-image" />
          </div>
        </Container>
        <div className="covervideo-wrapper">
          <VideoCover
            videoOptions={{
              src: heroVideUrl,
              autoPlay: true,
              muted: true,
              loop: true,
            }}
          />
        </div>
      </div>
      <Container>
        <Row>
          <Col sm={3} className="d-none d-md-block">
            <img src={vertLogo} alt="TFI" className="vert-logo" />
          </Col>
          <Col className="timeline-col">
            {slices.map((slice, index) => renderSlice(slice, index))}
          </Col>
        </Row>
      </Container>
      <div className="d-none d-md-block d-lg-block d-xl-block">
        <nav className="navbar fixed-bottom navbar-dark bg-dark justify-content-center">
          {years.map((year) => (
            <Scroll type="id" element={`id${year}`} offset={-100} key={year}>
              <span className="menu-year-item">
                <span className="dot">&#8226;</span>
                {year}
              </span>
            </Scroll>
          ))}
        </nav>
      </div>
      <GlobalFooter />
    </div>
  );
}


export default Timeline;
