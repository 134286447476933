/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Container } from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import GlobalFooter from '../Common/GlobalFooter';

class PressIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      entries: [],
      totalPages: 1,
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;
    this.getPrismic(pageParam);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.page !== match.params.page) {
      let pageParam = 1;
      if (match.params.page !== undefined) pageParam = match.params.page;
      this.getPrismic(pageParam);
    }
  }


  getPrismic(page) {
    const client = Prismic.client(APIENDPOINT);
    const predicates = [
      Prismic.Predicates.dateBefore('my.press_releases.date', moment().format('YYYY-MM-DD')),
      Prismic.Predicates.at('document.type', 'press_releases'),
    ];
    client.query(
      predicates,
      {
        pageSize: 9,
        page,
        orderings: '[my.press_releases.date desc]',
      },
    ).then((res) => {
      this.setState({
        page,
        entries: res.results,
        totalPages: res.total_pages,
      });
      // console.log(res);
    });
  }

  render() {
    const {
      entries,
      totalPages,
      page,
    } = this.state;
    const pageParam = page;
    // console.log(entries);

    return (
      <div className="press detail">
        <div className="bg-wrapper">
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <h1>Press Releases</h1>
            <Row>
              {entries.map((entry) => (
                <Col key={entry.id} lg={4} className="press-col">
                  <div className="press-date">{moment(entry.data.date).format('YYYY.MM.DD')}</div>
                  <h2><Link to={`/press/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
                  <div className="press-summary">{RichText.render(entry.data.summary)}</div>
                  <Link to={`/press/detail/${entry.uid}`} className="as-button lightblue">Learn More</Link>
                </Col>
              ))}
            </Row>
            <div className="page-nav">
              {parseInt(pageParam, 10) !== 1
                ? <Link to={`/press/${parseInt(pageParam, 10) - 1}`}>&#x25c0; &nbsp;</Link>
                : null }

              {`${pageParam} of ${totalPages}`}

              {parseInt(pageParam, 10) !== parseInt(totalPages, 10)
                ? <Link to={`/press/${parseInt(pageParam, 10) + 1}`}>&nbsp; &#x25b6;</Link>
                : null }
            </div>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

PressIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      page: PropTypes.string,
    }),
  }),
};

PressIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default PressIndex;
