import React from 'react';
import GoogleMapReact from 'google-map-react';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import { getCenter } from 'geolib';
import { GOOGLE_MAP_API } from '../../config/constant';

const AnyReactComponent = ({ label, setModalProjectId, project }) => (
  <div className="star-marker">
    <button type="button" onClick={() => setModalProjectId(project.uid)}>
      <span className="fas fa-map-marker-alt" />
      <div className="label">
        {label}
      </div>
    </button>
  </div>
);
AnyReactComponent.propTypes = {
  label: PropTypes.string.isRequired,
  setModalProjectId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
};

const StarMap = ({ markerEntries, setModalProjectId }) => {
  // console.log(markerEntries);
  const coordinates = markerEntries.map((row) => ({
    latitude: row.location.data.geopoint.latitude,
    longitude: row.location.data.geopoint.longitude,
  }));
  if (coordinates.length === 0) return (<div>Loading...</div>);
  const center = getCenter(coordinates);
  // console.log(center);
  const loadConfig = {
    center: {
      lat: center.latitude || 40.7579747,
      lng: center.longitude || -73.9855426,
    },
    zoom: 12,
  };
  // console.log(loadConfig.center);
  return (
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          // key: 'AIzaSyCnJYcqw1u8MmmBb_giL5qN3YAXiXyQxLg',
          key: GOOGLE_MAP_API,
        }}
        defaultCenter={loadConfig.center}
        defaultZoom={loadConfig.zoom}
      >
        {markerEntries.map((row) => (
          <AnyReactComponent
            key={row.location.id}
            label={RichText.asText(row.project.data.title)}
            lat={row.location.data.geopoint.latitude}
            lng={row.location.data.geopoint.longitude}
            project={row.project}
            setModalProjectId={setModalProjectId}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};
StarMap.propTypes = {
  markerEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
  setModalProjectId: PropTypes.func.isRequired,
};

export default StarMap;
