/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Col } from 'react-bootstrap';
import { Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import GlobalFooter from './Common/GlobalFooter';

const ContactStar = () => (
  <div className="contact-star">
    <div className="bg-wrapper">
      <Container>
        <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
          <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
        </div>
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <h1>Contact the STAR Initiative</h1>
            <form action="https://usebasin.com/f/c2377b392f29" method="POST">
              <label htmlFor="email-address">Your Email</label>
              <input type="email" id="email" name="email" required />
              <label htmlFor="name">Your Name</label>
              <input type="text" id="name" name="name" required />
              <label htmlFor="subject">Subject</label>
              <select id="subject" name="subject" required>
                <option value="Own a property and would like to participate">Own a property and would like to participate</option>
                <option value="Interested participating as an artist">Interested participating as an artist</option>
                <option value="Other">Other</option>
              </select>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" required />
              <input type="hidden" id="test" name="test" required />
              <button type="submit">Submit</button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
    <GlobalFooter />
  </div>
);
export default ContactStar;
