/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import {
  Row, Col,
} from 'react-bootstrap';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';

class FilmIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      totalPages: 1,
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // console.log(match.params);
    this.getEntries({
      page: match.params.page,
      program: match.params.program,
      year: match.params.year,
    });
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.getEntries({
        page: match.params.page,
        program: match.params.program,
        year: match.params.year,
      });
    }
  }

  getEntries({
    page,
    year,
    program,
    programId,
  }) {
    const client = Prismic.client(APIENDPOINT);
    if (program !== undefined && program !== '-' && programId === undefined) {
      client.getByUID('programs', program).then((res) => {
        // console.log(res);
        this.getEntries({
          page,
          year,
          program,
          programId: res.id,
        });
      });
    } else {
      const predicates = [
        Prismic.Predicates.at('document.type', 'people'),
      ];
      if (year !== undefined && year !== '-') {
        predicates.push(Prismic.Predicates.at('my.people.programs.year', year));
      }
      if (programId !== undefined) {
        predicates.push(Prismic.Predicates.at('my.people.programs.program', programId));
      }
      let pageFixed = 1;
      if (page !== undefined) pageFixed = page;
      client.query(
        predicates,
        {
          pageSize: 50,
          page: pageFixed,
          fetchLinks: ['programs.title'],
          orderings: '[my.people.name_sort]',
        },
      ).then((res) => {
        this.setState({
          entries: res.results,
          totalPages: res.total_pages,
        });
        // console.log(res.results[0]);
      });
    }
  }

  render() {
    const { entries, totalPages } = this.state;
    const { match } = this.props;
    // console.log(match);
    const nestedEntries = {};
    const hasPrograms = [];
    entries.forEach((entry) => {
      entry.data.programs.forEach((program) => {
        if (program.program.id !== undefined) {
          // console.log(program);
          const levelOne = RichText.asText(program.program.data.title);
          const levelTwo = Number(program.year);
          // console.log(program.program);
          if (match.params.program !== undefined && match.params.program !== '-' && match.params.program !== program.program.slug) return;
          if (match.params.year !== undefined && match.params.year !== '-' && match.params.year !== program.year) return;
          if (nestedEntries[levelOne] === undefined) {
            nestedEntries[levelOne] = {};
          }
          if (nestedEntries[levelOne][levelTwo] === undefined) {
            nestedEntries[levelOne][levelTwo] = [];
          }
          nestedEntries[levelOne][levelTwo].push(entry);
          hasPrograms.push(entry.id);
        }
      });
    });
    // console.log(nestedEntries);
    const programTitles = Object.keys(nestedEntries).sort();

    return (
      <>
        <h1>Filmmakers</h1>
        {programTitles.map((programTitle, p) => (
          <React.Fragment key={p}>
            <h2>{programTitle}</h2>
            {Object.keys(nestedEntries[programTitle]).sort().reverse().map((year, y) => (
              <React.Fragment key={y}>
                <h3>{year}</h3>
                <Row>
                  {nestedEntries[programTitle][year].map((entry, e) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col md={3} key={e}>
                      <div className="filmmaker_entry">
                        <Link to={`/filmmakers/detail/${entry.uid}`}>
                          <img src={`${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=250&h=250`} alt={RichText.asText(entry.data.name)} />
                          <h2>{RichText.asText(entry.data.name)}</h2>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <div>{totalPages} Pages</div>
      </>
    );
  }
}

FilmIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      program: PropTypes.string,
      year: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

FilmIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default FilmIndex;
