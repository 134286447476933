/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

import { trimPrismicUrl } from '../../helpers/prismic';

function DisplayDeadline(props) {
  const {
    showLogo,
    directLink,
    program,
  } = props;
  const {
    title,
    deadline_override,
    application_url,
    open,
    close,
    listing_logo,
    event_date,
  } = program.data;
  // console.log(program);

  let actionLabel = '';
  let deadlineCopy = '';
  let url = `/program/detail/${program.uid}`;
  if (program.type === 'program_events') {
    url = `/program/event/${program.uid}`;
  }

  const today = moment().unix();

  if (moment(open).unix() < today && moment(close).unix() > today) {
    // submission open
    actionLabel = directLink ? 'Apply Now' : 'Apply Now';
    deadlineCopy = `Apply Before ${moment(close).format('YYYY.MM.DD')}`;
    if (directLink) {
      url = application_url;
    }
  } else if (moment(open).unix() > today) {
    // submisison opening
    actionLabel = directLink ? 'Open Soon' : 'Learn More';
    deadlineCopy = `Submissions Open on ${moment(open).format('YYYY.MM.DD')}`;
    // url = `/program/detail/${program.uid}`;
  } else if (program.type === 'program_events' && event_date !== null && moment(event_date).unix() > today) {
    actionLabel = 'Learn More';
    deadlineCopy = `Event Date: ${moment(event_date).format('YYYY.MM.DD')}`;
    // url = `/program/detail/${program.uid}`;
  } else {
    actionLabel = 'Learn More';
    // url = `/program/detail/${program.uid}`;
  }

  if (deadline_override !== null && deadline_override !== '') {
    deadlineCopy = deadline_override;
  }

  const deadlineTitle = showLogo ? RichText.asText(title) : null;
  let deadlineLogo = null;
  if (
    listing_logo !== undefined
    && listing_logo.url !== undefined
    && showLogo === true
  ) {
    if (url.substring(0, 4) === 'http') {
      deadlineLogo = (
        <a href={url}>
          <img src={`${trimPrismicUrl(listing_logo.url)}&w=300`} alt="Logo" />
        </a>
      );
    } else {
      deadlineLogo = (
        <Link to={url}>
          <img src={`${trimPrismicUrl(listing_logo.url)}&w=300`} alt="Logo" />
        </Link>
      );
    }
  }

  return (
    <>
      {deadlineLogo}
      {deadlineTitle !== null ? <h4>{deadlineTitle}</h4> : null}
      {deadlineCopy !== '' ? <p>{deadlineCopy}</p> : null}
      {url.substring(0, 4) === 'http'
        ? <a href={url} className="as-button black">{actionLabel}</a>
        : <Link to={url} className="as-button black">{actionLabel}</Link> }
    </>
  );
}

DisplayDeadline.propTypes = {
  showLogo: PropTypes.bool,
  directLink: PropTypes.bool,
  program: PropTypes.shape({
    uid: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.arrayOf(PropTypes.object),
      deadline_override: PropTypes.string,
      application_url: PropTypes.string,
      open: PropTypes.string,
      close: PropTypes.string,
      event_date: PropTypes.string,
      listing_logo: PropTypes.object,
    }),
  }),
};
DisplayDeadline.defaultProps = {
  showLogo: false,
  directLink: false,
  program: {
    data: {},
    /*
    uid: '',
    data: {
      title: [],
      show_title: false,
      deadline_override: '',
      application_url: '',
      open: '',
      close: '',
      logo: {},
      program: {
        uid: '',
      },
      read_more: '',
    },
    */
  },
};

export default DisplayDeadline;
