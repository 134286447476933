import React from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import Slider from './Slider';

class Pinned extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        entries: [],
        title: [],
      },
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  // eslint-disable-next-line class-methods-use-this
  getPrismic() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(
        Prismic.Predicates.at('document.type', 'pinned_entries'),
        {
          fetchLinks: ['blogs.title', 'blogs.image'],
        },
      ).then((response) => {
        if (response) {
          // console.log(response);
          this.setState({
            entry: response.results[0].data,
          });
        }
      });
    });
  }

  render() {
    const { entry } = this.state;
    // console.log(entry);
    const slides = entry.entries.map((item) => ({
      uid: item.entry.uid,
      url: `/blog/detail/${item.entry.uid}`,
      title: RichText.asText(item.entry.data.title),
      image: `${trimPrismicUrl(item.entry.data.image.url)}&fit=crop&w=960&h=540`,
      button: 'Learn More',
    }));
    // console.log(slides);
    return (
      <Slider
        slides={slides}
        actionButton={{ url: '/blog', label: 'View Blog' }}
        title={RichText.asText(entry.title)}
      />
    );
  }
}

Pinned.propTypes = {
};

export default Pinned;
