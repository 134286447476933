import React from 'react';
import PropTypes from 'prop-types';

function SocialShareOut(props) {
  const { url, title } = props;
  const social = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      ico: 'fab fa-facebook-f',
    },
    {
      url: `https://twitter.com/intent/tweet?text=${url} ${title}`,
      ico: 'fab fa-twitter',
    },
    {
      url: `https://pinterest.com/pin/create/button/?url=${url}&media=&description=${title}`,
      ico: 'fab fa-pinterest',
    },
    {
      url: `mailto:info@example.com?&subject=&body=${url} ${title}`,
      ico: 'fas fa-envelope',
    },
  ];
  return (
    <div className="social-share">
      <ul className="inline">
        {social.map((soc, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i}>
            <a
              href={soc.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className={soc.ico}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

SocialShareOut.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SocialShareOut;
