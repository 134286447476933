import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../../config/constant';
import { trimPrismicUrl } from '../../helpers/prismic';

class ItemProject extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          image: {},
          logline: [],
          credits: [],
        },
      },
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    // console.log(item);
    if (item.id !== null || item.id !== undefined) {
      this.getEntries(item.id);
    }
  }

  getEntries(id) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('document.id', id),
      { fetchLinks: ['people.name', 'people.headshot', 'people.bio', 'programs.title'] },
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      // console.log(res.results[0]);
    });
  }

  render() {
    const { entry } = this.state;
    const { item } = this.props;
    if (item.id === null || item.id === undefined) return null;
    // console.log(entry);
    return (
      <div className="body-slice-project">
        {entry.data.image.url !== undefined
          ? (
            <Link to={`/films/detail/${entry.uid}`}>
              <img src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=800&h=450`} alt="still" className="project-image" />
            </Link>
          ) : null }
        <h3><Link to={`/films/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h3>
        <div className="logline">
          {RichText.render(entry.data.logline)}
        </div>
        <div className="credits">
          {entry.data.credits.map((credit, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <b>{`${credit.role}: `}</b>
              {RichText.asText(credit.people.data.name)}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ItemProject.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
    }),
  }),
  item: PropTypes.shape({
    id: PropTypes.string,
  }),
};

ItemProject.defaultProps = {
  match: {
    params: {},
  },
  item: {},
};
export default ItemProject;
