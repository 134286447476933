/* eslint-disable class-methods-use-this */
import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import {
  Row, Col,
} from 'react-bootstrap';

import { APIENDPOINT, FILM_PLACEHOLDER_URL } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';

class FilmIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      totalPages: 1,
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // console.log(match.params);
    this.getEntries({
      page: match.params.page,
      program: match.params.program,
      year: match.params.year,
    });
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.getEntries({
        page: match.params.page,
        program: match.params.program,
        year: match.params.year,
      });
    }
  }

  getEntries({
    page,
    year,
    program,
    programId,
  }) {
    const client = Prismic.client(APIENDPOINT);
    if (program !== undefined && program !== '-' && programId === undefined) {
      client.getByUID('programs', program).then((res) => {
        // console.log(res);
        this.getEntries({
          page,
          year,
          program,
          programId: res.id,
        });
      });
    } else {
      const predicates = [
        Prismic.Predicates.at('document.type', 'projects'),
      ];
      if (year !== undefined && year !== '-') {
        predicates.push(Prismic.Predicates.at('my.projects.programs.year', year));
      }
      if (programId !== undefined) {
        predicates.push(Prismic.Predicates.at('my.projects.programs.program', programId));
      }
      let pageFixed = 1;
      if (page !== undefined) pageFixed = page;
      client.query(
        predicates,
        {
          pageSize: 100,
          page: pageFixed,
          fetchLinks: ['programs.title'],
          orderings: '[my.projects.programs.year desc, my.projects.programs.program desc,]',
        },
      ).then((res) => {
        this.setState((prevState) => ({
          entries: prevState.entries.concat(res.results),
          totalPages: res.total_pages,
        }));
        if (res.page < res.total_pages) {
          this.getEntries({
            page: pageFixed + 1,
            year,
            program,
            programId,
          });
        }
        // console.log(res.results[0]);
      });
    }
  }

  render() {
    const { entries, totalPages } = this.state;
    const { match } = this.props;
    const nestedEntries = {};
    const hasPrograms = [];
    entries.forEach((entry) => {
      entry.data.programs.forEach((program) => {
        if (program.program.id !== undefined && program.program.type !== 'broken_type') {
          const levelOne = RichText.asText(program.program.data.title);
          const levelTwo = Number(program.year);
          if (match.params.program !== undefined && match.params.program !== '-' && match.params.program !== program.program.uid) return;
          if (match.params.year !== undefined && match.params.year !== '-' && match.params.year !== program.year) return;

          if (nestedEntries[levelOne] === undefined) {
            nestedEntries[levelOne] = {};
          }
          if (nestedEntries[levelOne][levelTwo] === undefined) {
            nestedEntries[levelOne][levelTwo] = [];
          }
          nestedEntries[levelOne][levelTwo].push(entry);
          hasPrograms.push(entry.id);
        }
      });
    });
    // console.log(nestedEntries);
    const programTitles = Object.keys(nestedEntries).sort();
    return (
      <>
        <h1>Projects</h1>
        {programTitles.map((programTitle, p) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={p}>
            <h2>{programTitle}</h2>
            {Object.keys(nestedEntries[programTitle]).sort().reverse().map((year, y) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={y}>
                <h3>{year}</h3>
                <Row>
                  {nestedEntries[programTitle][year].map((entry, e) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col md={4} key={e}>
                      <div className="film_entry">
                        <Link to={`/films/detail/${entry.uid}`}>
                          {entry.data.image.url !== undefined ? (
                            <img src={`${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=480&h=270`} alt={RichText.asText(entry.data.title)} />
                          ) : (
                            <img src={`${trimPrismicUrl(FILM_PLACEHOLDER_URL)}&fit=crop&w=480&h=270`} alt={RichText.asText(entry.data.title)} />
                          )}
                          {RichText.asText(entry.data.title)}
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        {/**
        <h2>Missing Programs</h2>
        {entries.map((entry, e) => (
          <React.Fragment key={e}>
            {hasPrograms.indexOf(entry.id) === -1
              ? (
                <p>
                  <Link to={`/filmmakers/detail/${entry.uid}`}>
                    {RichText.asText(entry.data.name)}
                  </Link>
                </p>
              ) : null
            }
          </React.Fragment>
        ))}
         */}
        <p>{`Total Pages: ${totalPages}`}</p>
      </>
    );
  }
}

FilmIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      program: PropTypes.string,
      year: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

FilmIndex.defaultProps = {
  match: {
    params: {},
  },
};

export default FilmIndex;
