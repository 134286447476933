import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import { APIENDPOINT } from './config/constant';
import GlobalFooter from './Common/GlobalFooter';

class Search extends React.Component {
  static renderEntry(entry) {
    const wordCount = 45;
    switch (entry.type) {
      case 'blogs':
        return (
          <>
            <h2><Link to={`/blog/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
            <div className="teaser">
              {entry.data.teaser !== undefined
                ? RichText.asText(entry.data.teaser).split(' ').splice(0, wordCount).join(' ')
                : null}
            </div>
            <div className="type">Blog</div>
          </>
        );
      case 'programs':
        return (
          <>
            <h2><Link to={`/program/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
            <div className="teaser">
              {entry.data.key_points[0] !== undefined && entry.data.key_points[0].info !== undefined
                ? RichText.asText(entry.data.key_points[0].info).split(' ').splice(0, wordCount).join(' ')
                : null}
            </div>
            <div className="type">Program</div>
          </>
        );
      case 'people':
        return (
          <>
            <h2><Link to={`/filmmakers/detail/${entry.uid}`}>{RichText.asText(entry.data.name)}</Link></h2>
            <div className="teaser">
              {entry.data.bio !== undefined
                ? RichText.asText(entry.data.bio).split(' ').splice(0, wordCount).join(' ')
                : null}
            </div>
            <div className="type">Person</div>
          </>
        );
      case 'projects':
        return (
          <>
            <h2><Link to={`/films/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
            <div className="teaser">
              {entry.data.logline !== undefined
                ? RichText.asText(entry.data.logline).split(' ').splice(0, wordCount).join(' ')
                : null}
            </div>
            <div className="type">Project</div>
          </>
        );
      case 'events':
        return (
          <>
            <h2><Link to={`/events/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link></h2>
            <div className="teaser">
              {entry.data.description !== undefined
                ? RichText.asText(entry.data.description).split(' ').splice(0, wordCount).join(' ')
                : null}
            </div>
            <div className="type">Event</div>
          </>
        );
      default:
        // console.log(entry);
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      redirect: '',
      totalPages: 1,
    };
    this.getPrismic = this.getPrismic.bind(this);
    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      prevProps.match.params.searchTerm !== match.params.searchTerm
      || prevProps.match.params.page !== match.params.page
    ) {
      this.getPrismic();
    }
  }

  onSearchKeyUp(event) {
    const search = event.target.value;
    this.setState({
      redirect: `/search/${encodeURIComponent(search)}`,
    });
  }

  getPrismic() {
    const { match } = this.props;
    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;
    if (match.params.searchTerm !== undefined) {
      const client = Prismic.client(APIENDPOINT);
      client.query(
        [
          Prismic.Predicates.any('document.type', ['programs', 'projects', 'people', 'blogs', 'events']),
          Prismic.Predicates.fulltext('document', decodeURIComponent(match.params.searchTerm)),
        ],
        {
          pageSize: 10,
          page: pageParam,
        },
      ).then((res) => {
        // console.log(res);
        this.setState({
          entries: res.results,
          totalPages: res.total_pages,
        });
      });
    } else {
      this.setState({
        entries: [],
        totalPages: 0,
      });
    }
  }

  render() {
    const { entries, redirect, totalPages } = this.state;
    const { match } = this.props;
    let search = '';
    if (match.params.searchTerm !== undefined) search = match.params.searchTerm;

    let pageParam = 1;
    if (match.params.page !== undefined) pageParam = match.params.page;
    // console.log(entries);
    return (
      <div>
        {redirect !== '' ? <Redirect to={redirect} /> : null}
        <div className="search">
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={8}>
                <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
                  <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
                </div>
                <div className="search-box">
                  <input type="text" value={decodeURIComponent(search)} onChange={this.onSearchKeyUp} />
                  <span className="noun icon-search" />
                </div>
                <div className="search-body">
                  {entries.map((entry, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="search-row">
                      {Search.renderEntry(entry)}
                    </div>
                  ))}
                </div>
                <div className="page-nav">
                  {pageParam !== 1
                    ? <Link to={`/search/${encodeURIComponent(search)}/${parseInt(pageParam, 10) - 1}`}>&#x25c0; &nbsp;</Link>
                    : null }
                  {`${pageParam} of ${totalPages}`}

                  {parseInt(pageParam, 10) !== parseInt(totalPages, 10)
                    ? <Link to={`/search/${encodeURIComponent(search)}/${parseInt(pageParam, 10) + 1}`}>&nbsp; &#x25b6;</Link>
                    : null }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

Search.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchTerm: PropTypes.string,
      page: PropTypes.string,
    }),
  }).isRequired,
};

Search.defaultProps = {
};

export default Search;
