/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import moment from 'moment';

import { APIENDPOINT } from '../config/constant';
import GlobalFooter from '../Common/GlobalFooter';
import DisplayDeadline from './common/DisplayDeadline';
// import Pinned from '../Common/Pinned';

class ProgramGroup extends React.Component {
  static calcProgramSortValue(program) {
    let sortValue = 5000000000;
    const today = moment().unix();
    const { close, open, event_date } = program.data;
    if (close !== null && open !== null) {
      if (moment(open).unix() < today && moment(close).unix() > today) {
        // submission open
        sortValue = 1000000000 + moment(close).unix();
      } else if (moment(open).unix() > today) {
        // submission openning
        sortValue = 2000000000 + moment(open).unix();
      }
    }
    if (event_date !== undefined && event_date !== null) {
      sortValue = 2000000000 + moment(event_date).unix();
    }
    return sortValue;
  }

  static filterProgram({ uiFilter, programEntries }) {
    let allTrue = true;
    Object.keys(uiFilter).forEach((key) => {
      if (uiFilter[key] !== true) allTrue = false;
    });
    if (allTrue) return programEntries;

    const programIds = [];
    programEntries.forEach((program) => {
      let show = false;
      if (program.data.filters !== undefined) {
        program.data.filters.forEach((filter) => {
          Object.keys(uiFilter).forEach((key) => {
            if (filter.filter.id === key && uiFilter[key]) show = true;
          });
        });
      }
      if (show) programIds.push(program.id);
    });

    // console.log(programEntries);
    // console.log(programIds);
    const filteredProgramEntries = [];
    programEntries.forEach((program) => {
      if (
        (programIds.indexOf(program.id) !== -1)
        || (programIds.indexOf(program.data.program) !== -1)
      ) {
        filteredProgramEntries.push(program);
      }
    });
    return filteredProgramEntries;
  }

  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          description: [],
          filters: [],
          programs: [],
          image: {
            url: '',
          },
        },
      },
      uiFilter: {},
      programs: [],
      programEvents: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.uid !== undefined) this.getPrismic(match.params.uid);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.uid !== match.params.uid) this.getPrismic(match.params.uid);
  }

  getPrismic(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.program_groups.uid', uid),
      {
        fetchLinks: [
          'programs.title',
          'program_filters.filter',
          'program_filters.description',
        ],
      },
    ).then((res) => {
      // console.log(res);
      const uiFilter = {};
      res.results[0].data.filters.forEach((filter) => {
        uiFilter[filter.program_filter.id] = true;
      });
      this.setState({
        entry: res.results[0],
        uiFilter,
      });

      const programIds = [];
      res.results[0].data.programs.forEach((program) => {
        if (program.program.id !== undefined) programIds.push(program.program.id);
      });

      // get full program info
      if (programIds.length > 0) {
        client.query(
          Prismic.Predicates.in('document.id', programIds),
          {
            fetchLinks: [
              'program_filters.filter',
              'program_filters.description',
            ],
          },
        ).then((res2) => {
          // console.log(res2);
          this.setState({
            programs: res2.results,
          });
        });
      }

      // get program events
      client.query(
        [
          Prismic.Predicates.any('my.program_events.program', programIds),
          Prismic.Predicates.dateAfter('my.program_events.event_date', moment().format('YYYY-MM-DD')),
        ],
        {},
      ).then((res2) => {
        // console.log(res2);
        this.setState({
          programEvents: res2.results,
        });
      });
    });
  }

  handleProgramFilterClick(filterId) {
    const { uiFilter } = this.state;
    uiFilter[filterId] = !uiFilter[filterId];
    this.setState({ uiFilter });
  }

  render() {
    const {
      entry,
      programs,
      programEvents,
      uiFilter,
    } = this.state;

    const style = {};
    if (entry.data.image.url !== undefined) {
      style.backgroundImage = `url(${entry.data.image.url}&w=1400)`;
    }
    const programsToShow = ProgramGroup.filterProgram({
      uiFilter,
      programEntries: programs.concat(programEvents),
    });

    // const programsToShow = programs.concat(programEvents);
    // console.log(programsToShow);
    programsToShow.sort((a, b) => {
      const aCompare = ProgramGroup.calcProgramSortValue(a);
      const bCompare = ProgramGroup.calcProgramSortValue(b);
      if (aCompare < bCompare) return -1;
      if (aCompare > bCompare) return 1;
      return 0;
    });
    return (
      <div className="program-group">
        <div className="hero-bg" style={style}>
          <div className="hero-fade">
            <Container>
              <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
                <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
              </div>
              <h1>{RichText.asText(entry.data.title)}</h1>
              <div className="hero-body">
                {RichText.render(entry.data.description)}
              </div>
            </Container>
          </div>
        </div>
        <Container>
          <div className="hero-photo-credit">{entry.data.image.alt}</div>
          <Row>
            <Col md={5} lg={4}>
              <div className="filter">
                <h3>Filter By Category</h3>
                {entry.data.filters.map((filter) => (
                  <div className="filter-row" key={filter.program_filter.id}>
                    <Switch
                      checked={uiFilter[filter.program_filter.id]}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      checkedIcon={false}
                      height={25}
                      className="react-switch"
                      onChange={() => this.handleProgramFilterClick(filter.program_filter.id)}
                    />
                    <span className="label">{RichText.asText(filter.program_filter.data.filter)}</span>
                  </div>
                ))}
              </div>
            </Col>
            <Col>
              {entry.data.filters.map((filter) => (
                <React.Fragment key={filter.program_filter.id}>
                  {uiFilter[filter.program_filter.id]
                    ? (
                      <div className="genre-description" key={filter.program_filter.id}>
                        <h2>{RichText.asText(filter.program_filter.data.filter)}</h2>
                        {RichText.render(filter.program_filter.data.description)}
                      </div>
                    ) : null }
                </React.Fragment>
              ))}
            </Col>
          </Row>

          <div className="program-detail">
            <h3>Programs</h3>
            <Row>
              {programsToShow.map((program) => (
                <Col md={6} lg={6} xl={4} key={program.id}>
                  <DisplayProgram
                    program={program}
                    programs={programs}
                    highlightActive={entry.data.highlight_active}
                  />
                </Col>
              ))}
            </Row>
          </div>
          {/**
            <Pinned />
          */}
        </Container>
        <GlobalFooter />
      </div>
    );
  }
}

ProgramGroup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
    }),
  }),
};
ProgramGroup.defaultProps = {
  match: {
    params: {
      uid: '',
    },
  },
};

export default ProgramGroup;

function DisplayProgram(props) {
  const {
    program,
    programs,
    highlightActive,
  } = props;
  // console.log(highlightActive);
  const cleanFilters = [];
  if (program.type === 'programs') {
    program.data.filters.forEach((filter) => {
      if (filter.filter.id !== undefined) cleanFilters.push(filter);
    });
  }
  if (program.type === 'program_events') {
    programs.forEach((programRow) => {
      if (programRow.id === program.data.program.id) {
        programRow.data.filters.forEach((filter) => {
          if (filter.filter.id !== undefined) cleanFilters.push(filter);
        });
      }
    });
  }
  let className = '';
  const today = moment().unix();
  if (
    program.type === 'programs'
    && highlightActive
    && (
      program.data.close === null
      || moment(program.data.close).unix() < today
    )
  ) {
    className = 'inactive';
  }
  if (program.type === 'program_events' && (program.data.event_date === null || moment(program.data.event_date).unix() < today)) {
    className = 'inactive';
  }
  let url = `/program/detail/${program.uid}`;
  if (program.type === 'program_events') {
    url = `/program/event/${program.uid}`;
  }
  return (
    <div className={`program-col ${className}`}>
      <div className="program-filter">
        {cleanFilters.map((filter) => (
          <span key={filter.filter.id}>{`#${RichText.asText(filter.filter.data.filter)} `}</span>
        ))}
      </div>
      <h2><Link to={url}>{RichText.asText(program.data.title)}</Link></h2>
      <DisplayDeadline program={program} />
    </div>
  );
}
DisplayProgram.propTypes = {
  program: PropTypes.shape({
    uid: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.arrayOf(PropTypes.object),
      filters: PropTypes.arrayOf(PropTypes.object),
      program: PropTypes.object,
      close: PropTypes.string,
      event_date: PropTypes.string,
    }),
  }),
  programs: PropTypes.arrayOf(PropTypes.object),
  highlightActive: PropTypes.bool,
};
DisplayProgram.defaultProps = {
  program: {
    data: {},
  },
  programs: [],
  highlightActive: false,
};
