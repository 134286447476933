import React from 'react';

import TopBanner from './Common/TopBanner';
import GlobalFooter from './Common/GlobalFooter';

function About() {
  return (
    <div className="about index">
      <TopBanner uid="about-tfi" />
      <GlobalFooter />
    </div>
  );
}


export default About;
