import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import {
  APIENDPOINT, SITE_URL, SITE_NAME, SITE_IMAGE,
} from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import SocialShareOut from '../Common/SocialShareOut';

class FilmDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          headshot: {},
          name: [],
          programs: [],
          links: [],
          bio: [],
        },
      },
      films: [],
    };
    this.getEntries = this.getEntries.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    let uidParam = '';
    if (match.params.uid !== undefined) uidParam = match.params.uid;
    this.getEntries(uidParam);
  }

  getEntries(uid) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.people.uid', uid),
      { fetchLinks: ['programs.title'] },
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      if (res.results.length > 0) {
        client.query(
          [
            Prismic.Predicates.at('document.type', 'projects'),
            Prismic.Predicates.at('my.projects.credits.people', res.results[0].id),
          ],
        ).then((resFilm) => {
          this.setState({
            films: resFilm.results,
          });
          // console.log(resFilm.results[0]);
        });
      }
      // console.log(res.results[0]);
    });
  }

  render() {
    const { entry, films } = this.state;
    const programsToShow = [];
    entry.data.programs.forEach((program) => {
      if (program.program.id !== undefined) programsToShow.push(program);
    });
    return (
      <>
        <Helmet>
          <title>{`${RichText.asText(entry.data.name)} - ${SITE_NAME}`}</title>
          <meta name="description" content={RichText.asText(entry.data.bio)} />

          <meta property="og:title" content={RichText.asText(entry.data.name)} />
          <meta property="og:description" content={RichText.asText(entry.data.bio)} />

          <meta property="og:type" content="article" />
          <meta property="og:image" content={entry.data.headshot.url ? entry.data.headshot.url : SITE_IMAGE} />
        </Helmet>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <img
              src={`${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=250&h=250`}
              alt={RichText.asText(entry.data.name)}
              className="headshot"
            />
            <h1>{RichText.asText(entry.data.name)}</h1>
            <Row>
              <Col>
                <div className="programs">
                  {programsToShow.length > 0 ? <h3>TFI Support</h3> : null}
                  {programsToShow.map((program, p) => (
                    <p>
                      <Link to={`/programs/detail/${program.program.uid}`}>{RichText.asText(program.program.data.title)}</Link>
                      {' '}
                      {program.year}
                      {' '}
                      {program.program_note}
                    </p>
                  ))}
                </div>
                <div className="filmmaker-body">
                  <h2>About</h2>
                  {RichText.render(entry.data.bio)}
                </div>
                <div className="films">
                  {films.map((filmEntry, e) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={e}>
                      <Link to={`/films/detail/${filmEntry.uid}`}>
                        <img
                          className="still"
                          src={`${trimPrismicUrl(filmEntry.data.image.url)}&fit=crop&w=960&h=540`}
                          alt={RichText.asText(filmEntry.data.title)}
                        />
                      </Link>
                      <h2>
                        <Link to={`/films/detail/${filmEntry.uid}`}>
                          {RichText.asText(filmEntry.data.title)}
                        </Link>
                      </h2>
                      {RichText.render(filmEntry.data.logline)}
                    </React.Fragment>
                  ))}
                </div>
              </Col>
              <Col lg={3}>
                <div className="Links">
                  {entry.data.links.map((link, l) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={l}>
                      <a href={link.url.url} target="_blank" rel="noopener noreferrer">{link.platform}</a>
                    </p>
                  ))}
                </div>
                <SocialShareOut
                  url={`${SITE_URL}/filmmakers/detail/${entry.uid}`}
                  title={RichText.asText(entry.data.name)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

FilmDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
    }),
  }),
};

FilmDetail.defaultProps = {
  match: {
    params: {},
  },
};
export default FilmDetail;
