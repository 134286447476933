import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route,
} from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';
import FilmIndex from './FilmIndex';
import FilmDetail from './FilmDetail';
import FilmAll from './FilmAll';
import FilmAllStar from './FilmAllStar';
import GlobalFooter from '../Common/GlobalFooter';

class FilmRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { location } = this.props;
    const className = location.pathname.split('/').join(' ');
    return (
      <div className={`${className}`}>
        <div className="bg-wrapper">
          <Container>
            <Switch>
              <Route exact path="/films" component={FilmAll} />
              <Route exact path="/projects/star" component={FilmAllStar} />
              <Route exact path="/films/all" component={FilmAll} />
              <Route exact path="/films/all/:page" component={FilmAll} />
              <Route exact path="/films/detail/:uid" component={FilmDetail} />
              <Route exact path="/projects/detail-star/:uid" component={FilmDetail} />
              <Route exact path="/films/:program" component={FilmIndex} />
              <Route exact path="/films/:program/:year" component={FilmIndex} />
            </Switch>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}


FilmRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
FilmRoute.defaultProps = {
};
export default FilmRoute;
