import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route,
} from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';


import BlogIndex from './BlogIndex';
import BlogDetail from './BlogDetail';
import BlogIndexByCat from './BlogIndexByCat';
import BlogIndexByProgram from './BlogIndexByProgram';
import GlobalFooter from '../Common/GlobalFooter';

class BlogRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { location } = this.props;
    const className = location.pathname.split('/').join(' ');
    return (
      <div className={`${className}`}>
        <div className="bg-wrapper">
          <Container>
            <Switch>
              <Route exact path="/blog" component={BlogIndex} />
              <Route exact path="/blog/:page" component={BlogIndex} />
              <Route exact path="/blog/category/:cat" component={BlogIndexByCat} />
              <Route exact path="/blog/category/:cat/:page" component={BlogIndexByCat} />
              <Route exact path="/blog/program/:cat" component={BlogIndexByProgram} />
              <Route exact path="/blog/program/:cat/:page" component={BlogIndexByProgram} />
              <Route exact path="/blog/detail/:uid" component={BlogDetail} />
            </Switch>
          </Container>
        </div>
        <GlobalFooter />
      </div>
    );
  }
}

BlogRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default BlogRoute;
