/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../config/constant';
import DisplayDeadline from './common/DisplayDeadline';
import GlobalFooter from '../Common/GlobalFooter';
import SliceByYear from './common/SliceByYear';
import SliceByEntryIds from './common/SliceByEntryIds';

class ProgramDeadline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entry: {
        data: {
          title: [],
          image: {},
          key_points: [],
          deadlines: [],
          hint: [],
          body: [],
          sponsor_label: [],
          sponsors: [],
        },
      },
      deadline: {
        data: {
          title: [],
          logo: {},
          program: {},
          application_info: [],
          key_points: [],
        },
      },
      currentKP: '',
    };
    this.getPrismic = this.getPrismic.bind(this);
    this.handleKPClick = this.handleKPClick.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.getPrismic(match.params.uid, match.params.deadlineId);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.deadlineId !== match.params.deadlineId) {
      this.getPrismic(match.params.uid, match.params.deadlineId);
    }
  }

  getPrismic(uid, deadlineId) {
    const client = Prismic.client(APIENDPOINT);
    client.query(
      Prismic.Predicates.at('my.programs.uid', uid),
      { fetchLinks: [] },
    ).then((res) => {
      this.setState({
        entry: res.results[0],
      });
      // console.log(res.results[0]);
    });
    client.query(
      [
        Prismic.Predicates.at('my.application_deadlines.uid', deadlineId),
      ],
      {
        fetchLinks: [],
      },
    ).then((res2) => {
      let currentKP = '';
      if (res2.results[0].data.key_points[0] !== undefined) {
        currentKP = res2.results[0].data.key_points[0].heading;
      }
      // console.log(res2);
      this.setState({
        deadline: res2.results[0],
        currentKP,
      });
    });
  }

  handleKPClick(kp) {
    const { currentKP } = this.state;
    if (currentKP === kp) {
      this.setState({ currentKP: '' });
    } else {
      this.setState({ currentKP: kp });
    }
  }

  render() {
    const { entry, deadline, currentKP } = this.state;
    const style = {
      backgroundColor: '#6FAFDB',
    };
    if (
      entry.data.background_color !== undefined
      && entry.data.background_color !== null
    ) style.backgroundColor = entry.data.background_color;

    const kpToShow = [];
    deadline.data.key_points.forEach((kp) => {
      if (kp.heading !== '' && RichText.asText(kp.info) !== '') kpToShow.push(kp);
    });

    const sponsorToShow = [];
    entry.data.sponsors.forEach((sponsor) => {
      if (sponsor.logo.url !== undefined) sponsorToShow.push(sponsor);
    });

    // console.log(entry.data.deadlines);
    // console.log(entry);
    return (
      <div className="program-detail">
        <div className="hero" style={style}>
          <Container>
            <div className="d-none d-md-block d-lg-block d-xl-block hero-logo">
              <Link to="/"><img src="/img/tfi_w.png" alt="Tribeca Film Institute" /></Link>
            </div>
            <div className="table-wrapper">
              <div className="program-logo">
                {entry.data.image.url !== undefined
                  ? <img src={`${entry.data.image.url}&w=500&h=200`} alt={RichText.asText(entry.data.title)} />
                  : null }
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <Row>
            <Col md={4}>
              <div className="deadlines">
                <h3>Entry Deadline</h3>
                <DisplayDeadline deadline={deadline} showLogo directLink />
              </div>
              <div className="sidebar-note">
                <p>
                  Return to
                  {' '}
                  <Link to={`/program/detail/${entry.uid}`}>{RichText.asText(entry.data.title)}</Link>
                </p>
              </div>

            </Col>
            <Col>
              <h1>{RichText.asText(deadline.data.title)}</h1>
              {kpToShow.map(kp => (
                <div className="keypoints" key={kp.heading}>
                  <button type="button" onClick={() => this.handleKPClick(kp.heading)} className="as-text heading">
                    <span className={`icon-${currentKP === kp.heading ? 'minus' : 'plus'}`} />
                    {' '}
                    {kp.heading}
                  </button>
                  <div className={`info ${currentKP === kp.heading ? 'active' : ''}`}>{RichText.render(kp.info)}</div>
                </div>
              ))}
            </Col>
          </Row>
          {sponsorToShow.length > 0
            ? (
              <div className="funder">
                <h2>{RichText.asText(entry.data.sponsor_label)}</h2>
                {entry.data.sponsors.map(sponsor => (
                  <Row className="justify-content-md-center">
                    <Col md={3}>
                      <a href={sponsor.url.url}>
                        <img src={sponsor.logo.url} alt={sponsor.label} />
                      </a>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : null }
        </Container>
        <GlobalFooter />
      </div>
    );
  }
}

ProgramDeadline.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.oneOfType([
        PropTypes.string,
      ]),
      deadlineId: PropTypes.string,
    }),
  }),
};

ProgramDeadline.defaultProps = {
  match: {
    params: {},
  },
};
export default ProgramDeadline;

function DisplaySlice(props) {
  const { slice, programId } = props;
  // console.log(slice);
  switch (slice.slice_type) {
    case 'by_year':
      return (<SliceByYear slice={slice} programId={programId} />);
    case 'specific_entries':
      return (<SliceByEntryIds slice={slice} programId={programId} />);
    default:
      return (
        <div />
      );
  }
}

DisplaySlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
  }),
  programId: PropTypes.string.isRequired,
};

DisplaySlice.defaultProps = {
  slice: {
    slice_type: '',
  },
};
