import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { Row, Col } from 'react-bootstrap';

import ItemProject from './ItemProject';

function RenderBodySlice(props) {
  const { slice } = props;
  // console.log(slice);
  switch (slice.slice_type) {
    case 'linked_items':
      return (
        <Row>
          {slice.items.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col lg={12} key={i}>
              <RenderLinkedItems item={item.item} />
            </Col>
          ))}
        </Row>
      );
    case 'text_block':
      return <div>{RichText.render(slice.primary.text)}</div>;
    default:
      // eslint-disable-next-line no-console
      console.log(slice.slice_type);
  }
  return <div />;
}
RenderBodySlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    primary: PropTypes.shape({
      text: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

RenderBodySlice.defaultProps = {
  slice: {
    items: [],
  },
};

export default RenderBodySlice;

function RenderLinkedItems(props) {
  const { item } = props;
  // console.log(item);
  switch (item.type) {
    case 'projects':
      return <ItemProject item={item} />;
    default:
      // eslint-disable-next-line no-console
      console.log(item.type);
  }
  return <div />;
}

RenderLinkedItems.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};
