import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import { APIENDPOINT } from '../../config/constant';

import Slider from '../../Common/Slider';
import { trimPrismicUrl } from '../../helpers/prismic';

class SliceByYear extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const { slice, programId } = this.props;
    // console.log({ slice, programId });
    // console.log(slice.primary.year);

    let predicates = [];
    let orderings = '';

    switch (slice.primary.type) {
      case 'People':
        predicates = [
          Prismic.Predicates.at('document.type', 'people'),
          Prismic.Predicates.at('my.people.programs.year', slice.primary.year),
          Prismic.Predicates.at('my.people.programs.program', programId),
        ];
        if (slice.primary.program_note !== null) {
          predicates.push(Prismic.Predicates.at('my.people.programs.program_note', slice.primary.program_note));
        }
        orderings = '[my.people.name_sort]';
        break;
      case 'Projects':
        predicates = [
          Prismic.Predicates.at('document.type', 'projects'),
          Prismic.Predicates.at('my.projects.programs.year', slice.primary.year),
          Prismic.Predicates.at('my.projects.programs.program', programId),
        ];
        if (slice.primary.program_note !== null) {
          predicates.push(Prismic.Predicates.at('my.projects.programs.program_note', slice.primary.program_note));
        }
        orderings = '[my.projects.title_sort]';
        break;
      default:
        predicates = [
          Prismic.Predicates.at('document.type', 'oops'),
        ];
        break;
    }
    const client = Prismic.client(APIENDPOINT);
    client.query(
      predicates,
      { fetchLinks: [], orderings },
    ).then((res) => {
      this.setState({
        entries: res.results,
      });
      // console.log(res.results);
    });
  }


  render() {
    const { entries } = this.state;
    const { slice, programId } = this.props;
    const slides = [];
    let slidesToShow = 3;
    switch (slice.primary.type) {
      case 'People':
        entries.forEach((entry) => {
          let titleNote = '';
          entry.data.programs.forEach((program) => {
            if (program.program.id === programId && slice.primary.year === program.year) {
              if (program.program_note !== null) titleNote = program.program_note;
            }
          });

          // make sure both year and program is a match
          let hasBothYearProgram = false;
          entry.data.programs.forEach((programRow) => {
            if (
              programRow.year === slice.primary.year
              && programRow.program.id === programId
            ) hasBothYearProgram = true;
          });

          if (hasBothYearProgram) {
            slides.push({
              uid: entry.uid,
              title: RichText.asText(entry.data.name),
              titleNote,
              slideClass: 'people',
              image: `${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=350&h=350`,
              url: `/filmmakers/detail/${entry.uid}`,
            });
          }
        });
        slidesToShow = 4;
        break;
      case 'Projects':
        entries.forEach((entry) => {
          let titleNote = '';
          entry.data.programs.forEach((program) => {
            if (program.program.id === programId && slice.primary.year === program.year) {
              if (program.program_note !== null) titleNote = program.program_note;
            }
          });

          // make sure both year and program is a match
          let hasBothYearProgram = false;
          entry.data.programs.forEach((programRow) => {
            if (
              programRow.year === slice.primary.year
              && programRow.program.id === programId
            ) hasBothYearProgram = true;
          });

          if (hasBothYearProgram) {
            slides.push({
              uid: entry.uid,
              title: RichText.asText(entry.data.title),
              titleNote,
              image: `${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=640&h=360`,
              url: `/films/detail/${entry.uid}`,
            });
          }
        });
        slidesToShow = 3;
        break;
      default:
    }
    let title = `${slice.primary.slice_title}: ${slice.primary.year}`;
    if (RichText.asText(slice.primary.slice_title_custom) !== '') {
      title = RichText.asText(slice.primary.slice_title_custom);
    }
    const actionButton = {};
    if (
      slice.primary.action_url !== ''
      && slice.primary.action_url !== null
      && slice.primary.action_label !== ''
      && slice.primary.action_label !== null
    ) {
      actionButton.url = slice.primary.action_url;
      actionButton.label = slice.primary.action_label;
    }
    return (
      <Slider
        slides={slides}
        actionButton={actionButton}
        title={title}
        slidesToShow={slidesToShow}
      />
    );
  }
}

SliceByYear.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      slice_title: PropTypes.string,
      slice_title_custom: PropTypes.arrayOf(PropTypes.object),
      year: PropTypes.string,
      type: PropTypes.string,
      action_url: PropTypes.string,
      action_label: PropTypes.string,
      program_note: PropTypes.string,
    }),
  }),
  programId: PropTypes.string.isRequired,
};

SliceByYear.defaultProps = {
  slice: {
    primary: {
      slice_title_custom: [],
      slice_title: '',
      year: '',
      type: '',
      action_url: '',
      action_label: '',
      program_note: null,
    },
  },
};
export default SliceByYear;
