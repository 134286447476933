/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import {
  Row, Col, Modal,
} from 'react-bootstrap';
import camelcase from 'camelcase';
import PropTypes from 'prop-types';

const ProgramStarArtist = ({
  imageCache,
  artistIndex,
  artists,
  setArtistIndex,
}) => {
  const artist = artists[artistIndex];
  if (artistIndex === null) return null;
  return (
    <Modal
      show={artistIndex !== null}
      size="lg"
      onHide={() => setArtistIndex(null)}
      className="star-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{artist.nameOfArtistOrCollective.answer}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col lg={3} md={4} xs={6}>
              <img
                src={imageCache[artist.headshotOrArtCollectiveLogoImage.answer[0]]
                  ? imageCache[artist.headshotOrArtCollectiveLogoImage.answer[0]].replace(/\/upload\//, '/square/upload/')
                  : `${artist.headshotOrArtCollectiveLogoImage.answer[0]}?width=400&height=400`}
                alt="headshot"
                className="registry-headshot"
              />
            </Col>
            <Col>
              <div className="attributes">
                <div>
                  <span className="fas fa-map-marker-alt" />
                  {' '}
                  {artist[`neighborhoodOfResidencyOrStudio${camelcase(artist.boroughOfResidencyOrStudio.answer, { pascalCase: true })}`].answer}
                  {` (${artist.boroughOfResidencyOrStudio.answer})`}
                </div>
                <div>
                  <span className="fas fa-palette" />
                  {' '}
                  {artist.primaryArtisticDiscipline.answer ? artist.primaryArtisticDiscipline.answer.join(', ') : null}
                </div>
              </div>
              <div className="biography">
                {artist.biography.answer.split(/\r?\n/).map((par, i) => <p key={i}>{par}</p>)}
              </div>
              <div>
                Links:
                <ul>
                  {artist.resumeCv.answer[0] ? (
                    <li>
                      <a
                        src={imageCache[artist.resumeCv.answer[0]]
                          ? imageCache[artist.resumeCv.answer[0]]
                          : artist.resumeCv.answer[0]}
                        href={artist.resumeCv.answer[0]}
                      >
                        Resume / CV
                      </a>
                    </li>
                  ) : null}
                  {artist.instagramUrl.answer ? (
                    <li><a href={artist.instagramUrl.answer}>Instagram</a></li>
                  ) : null}
                  {artist.websiteUrl.answer ? (
                    <li><a href={artist.websiteUrl.answer}>Website</a></li>
                  ) : null}
                </ul>
              </div>
            </Col>
          </Row>
          <div className="past-work">
            <h3>Past Works</h3>
            {artist.urlToVideoOfPastWork.answer ? (
              <div className="past-video">
                <a href={artist.urlToVideoOfPastWork.answer}>
                  <span className="fas fa-video" />
                  {' '}
                  Video of Past Work
                </a>
              </div>
            ) : null}
            <div className="past-work-images">
              {artist.imageOfPastWork1 && artist.imageOfPastWork1.answer[0] ? (
                <div className="past-work-image">
                  <img
                    src={imageCache[artist.imageOfPastWork1.answer[0]]
                      ? imageCache[artist.imageOfPastWork1.answer[0]].replace(/\/upload\//, '/medium/upload/')
                      : `${artist.imageOfPastWork1.answer[0]}?height=200`}
                    alt="past work"
                    key={artist.imageOfPastWork1.answer[0]}
                  />
                  <div>{artist.captionForPastWork1.answer}</div>
                </div>
              ) : null}
              {artist.imageOfPastWork2 && artist.imageOfPastWork2.answer[0] ? (
                <div className="past-work-image">
                  <img
                    src={imageCache[artist.imageOfPastWork2.answer[0]]
                      ? imageCache[artist.imageOfPastWork2.answer[0]].replace(/\/upload\//, '/medium/upload/')
                      : `${artist.imageOfPastWork2.answer[0]}?height=200`}
                    alt="past work"
                    key={artist.imageOfPastWork2.answer[0]}
                  />
                  <div>{artist.captionForPastWork2.answer}</div>
                </div>
              ) : null}
              {artist.imageOfPastWork3 && artist.imageOfPastWork3.answer[0] ? (
                <div className="past-work-image">
                  <img
                    src={imageCache[artist.imageOfPastWork3.answer[0]]
                      ? imageCache[artist.imageOfPastWork3.answer[0]].replace(/\/upload\//, '/medium/upload/')
                      : `${artist.imageOfPastWork3.answer[0]}?height=200`}
                    alt="past work"
                    key={artist.imageOfPastWork3.answer[0]}
                  />
                  <div>{artist.captionForPastWork3.answer}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ProgramStarArtist.propTypes = {
  artistIndex: PropTypes.number,
  setArtistIndex: PropTypes.func.isRequired,
  artists: PropTypes.arrayOf(PropTypes.object),
  imageCache: PropTypes.object,
};

ProgramStarArtist.defaultProps = {
  artistIndex: null,
  artists: [],
  imageCache: {},
};
export default ProgramStarArtist;
