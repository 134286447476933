import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

import { APIENDPOINT } from '../../config/constant';

import Slider from '../../Common/Slider';
import { trimPrismicUrl } from '../../helpers/prismic';

class SliceByEntryIds extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
    this.getPrismic = this.getPrismic.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const { slice } = this.props;
    // console.log({ slice, programId });
    const ids = slice.items.map((item) => item.entries.id);
    const predicates = [Prismic.Predicates.in('document.id', ids)];
    const client = Prismic.client(APIENDPOINT);
    client.query(
      predicates,
      { fetchLinks: [] },
    ).then((res) => {
      this.setState({
        entries: res.results,
      });
      // console.log(res.results);
    });
  }


  render() {
    const { entries } = this.state;
    const { slice, programId } = this.props;
    const slides = [];
    let slidesToShow = 4;
    const title = RichText.asText(slice.primary.slice_title);
    // console.log(entries);
    entries.forEach((entry) => {
      let titleNote = '';
      const notes = [];
      switch (entry.type) {
        case 'people':
          entry.data.programs.forEach((program) => {
            if (program.program.id === programId) {
              let note = '';
              if (program.program_note !== null) note = ` ${program.program_note}`;
              notes.push(`${program.year}${note}`);
            }
          });
          titleNote = notes.join(', ');
          slides.push({
            uid: entry.uid,
            slideClass: 'people',
            title: RichText.asText(entry.data.name),
            titleNote,
            image: `${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=350&h=350`,
            url: `/filmmakers/detail/${entry.uid}`,
          });
          break;
        case 'projects':
          entry.data.programs.forEach((program) => {
            if (program.program.id === programId) {
              let note = '';
              if (program.program_note !== null) note = ` ${program.program_note}`;
              notes.push(`${program.year}${note}`);
            }
          });
          titleNote = notes.join(', ');
          slides.push({
            uid: entry.uid,
            title: RichText.asText(entry.data.title),
            titleNote,
            image: `${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=640&h=360`,
            url: `/films/detail/${entry.uid}`,
          });
          slidesToShow = 3;
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(`missing type: ${entry.type}`);
      }
    });
    /*
    switch (slice.primary.type) {
      case 'People':
        entries.forEach((entry) => {
          slides.push({
            uid: entry.uid,
            title: RichText.asText(entry.data.name),
            image: `${trimPrismicUrl(entry.data.headshot.url)}&fit=crop&w=350&h=350`,
            url: `/filmmakers/detail/${entry.uid}`,
          });
        });
        slidesToShow = 4;
        break;
      case 'Projects':
        entries.forEach((entry) => {
          slides.push({
            uid: entry.uid,
            title: RichText.asText(entry.data.title),
            image: `${trimPrismicUrl(entry.data.image.url)}&fit=crop&w=640&h=360`,
            url: `/films/detail/${entry.uid}`,
          });
        });
        slidesToShow = 3;
        break;
      default:
    }
    let title = `${slice.primary.slice_title}: ${slice.primary.year}`;
    if (RichText.asText(slice.primary.slice_title_custom) !== '') {
      title = RichText.asText(slice.primary.slice_title_custom);
    }
    */
    const actionButton = {};
    if (
      slice.primary.action_url !== ''
      && slice.primary.action_url !== null
      && slice.primary.action_label !== ''
      && slice.primary.action_label !== null
    ) {
      actionButton.url = slice.primary.action_url;
      actionButton.label = slice.primary.action_label;
    }
    return (
      <Slider
        slides={slides}
        actionButton={actionButton}
        title={title}
        slidesToShow={slidesToShow}
      />
    );
  }
}

SliceByEntryIds.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      slice_title: PropTypes.arrayOf(PropTypes.object),
      slice_title_custom: PropTypes.arrayOf(PropTypes.object),
      year: PropTypes.string,
      type: PropTypes.string,
      action_url: PropTypes.string,
      action_label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  programId: PropTypes.string.isRequired,
};

SliceByEntryIds.defaultProps = {
  slice: {
    primary: {
      slice_title_custom: [],
      slice_title: '',
      year: '',
      type: '',
      action_url: '',
      action_label: '',
    },
    items: [],
  },
};
export default SliceByEntryIds;
