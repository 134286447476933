import React from 'react';
import Prismic from '@prismicio/client';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
// import Switch from 'react-switch';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { APIENDPOINT } from '../config/constant';
import { trimPrismicUrl } from '../helpers/prismic';
import TopBanner from '../Common/TopBanner';
import GlobalFooter from '../Common/GlobalFooter';

class Team extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      // filters: [],
      uiFilter: {},
    };
    this.getPrismic = this.getPrismic.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  componentDidMount() {
    this.getPrismic();
  }

  getPrismic() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(
        Prismic.Predicates.at('document.type', 'staff_list'),
        {
          fetchLinks: ['staff.name', 'staff.title', 'staff.headshot', 'staff.consultant'],
        },
      ).then((response) => {
        if (response) {
          // console.log(response);
          const entry = response.results[0].data;
          const filters = [];
          const entries = [];
          const uiFilter = {};
          entry.body.forEach((slice) => {
            uiFilter[RichText.asText(slice.primary.department)] = true;
            const department = RichText.asText(slice.primary.department);
            filters.push(department);
            slice.items.forEach((item) => {
              entries.push({ department, staff: item.staff });
            });
          });
          this.setState({
            // filters,
            entries,
            uiFilter,
          });
        }
      });
    });
  }

  handleFilterClick(filterId) {
    const { uiFilter } = this.state;
    const newUIFilter = {};
    Object.keys(uiFilter).forEach((key) => {
      if (key === filterId) {
        newUIFilter[key] = true;
      } else {
        newUIFilter[key] = false;
      }
    });
    // console.log(uiFilter);
    this.setState({ uiFilter: newUIFilter });
  }

  render() {
    const {
      // filters,
      uiFilter,
      entries,
    } = this.state;

    let peopleToShow = [];
    let allTrue = true;
    Object.keys(uiFilter).forEach((key) => {
      if (uiFilter[key] === false) allTrue = false;
    });
    if (allTrue === false) {
      entries.forEach((entry) => {
        if (uiFilter[entry.department]) peopleToShow.push(entry);
      });
    } else {
      peopleToShow = entries;
    }

    return (
      <div className="team index">
        <TopBanner uid="meet-the-team" />
        <Container>
          <div>
            {/**
            <Row>
              <Col xl={5} lg={6} md={12}>
                <div className="filters">
                  <h3>Filter By Category</h3>
                  {filters.map((filter) => (
                    <div className="filter-row" key={filter}>
                      <Switch
                        checked={uiFilter[filter]}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        offHandleColor="#aaaaaa"
                        handleDiameter={30}
                        checkedIcon={false}
                        height={25}
                        className="react-switch"
                        onChange={() => this.handleFilterClick(filter)}
                      />
                      <span className="label">{filter}</span>
                    </div>
                  ))}
                </div>
              </Col>
              <Col>
                <blockquote className="big-quote">
                  Tacos enamel pin bitters tousled, street art adaptogen schlitz wolf vegan squid.
                  <footer>
                    <cite>Name Name</cite>
                  </footer>
                </blockquote>
              </Col>
            </Row>
            <h3 className="filter-heading">Filtered Results</h3>
            */}
            <Row>
              {peopleToShow.map((entry) => (
                <Col key={entry.staff.id} xl={4} lg={4} md={6}>
                  <div className="staff">
                    <Link to={`/team/${entry.staff.uid}`}>
                      <img
                        src={`${trimPrismicUrl(entry.staff.data.headshot.url)}&fit=crop&w=300&h=300`}
                        alt={RichText.asText(entry.staff.data.name)}
                        className="headshot"
                      />
                    </Link>
                    <div className="department">{`${entry.department}`}</div>
                    <h2><Link to={`/team/${entry.staff.uid}`}>{RichText.asText(entry.staff.data.name)}</Link></h2>
                    <div className="role">
                      {`${RichText.asText(entry.staff.data.title)} ${entry.staff.data.consultant === 'Yes' ? '(Consultant)' : ''}`}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
        <GlobalFooter />
      </div>
    );
  }
}

Team.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cat: PropTypes.string,
      page: PropTypes.string,
    }),
  }),
};

Team.defaultProps = {
  match: {
    params: {},
  },
};

export default Team;
